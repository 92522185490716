import React, {useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import {withMemo} from 'helpers/wrapper';
import StyledCollectionOnboarding
  from 'components/organisms/Onboardings/CollectionOnboarding/CollectionOnboarding.styles';
import TaskAlt from '@mui/icons-material/TaskAlt';
import utils from 'helpers/utils';
import Badge from 'components/atoms/Badges/Badge/Badge';
import Onboarding from 'components/organisms/Onboardings/Onboarding/Onboarding';
import ActionFab from 'components/molecules/Fabs/ActionFab/ActionFab';
import {Slide} from '@mui/material';
import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import {useAuthClient} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import constants from 'helpers/constants';
import {useCollectionStatStatus} from 'services/collection/stat/stat.utils';
import CircularProgress from 'components/atoms/Progress/CircularProgress/CircularProgress';
import Tooltip from 'components/atoms/Tooltips/Tooltip/Tooltip';
import ProgressTooltip from 'components/molecules/Tooltips/ProgressTooltip/ProgressTooltip';

const CollectionOnboarding = withMemo(React.forwardRef((props, ref) => {
  const {
    collection,
    showEmpty,
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'CollectionOnboarding');

  const popperRef = useRef(null);
  const profileProvider = useProfile();

  const client = useAuthClient();
  const hasAutoTags = Boolean(client?.props?.autotagPeriod !== constants.services.periods.never) || Boolean(collection?.autotagPeriod !== constants.services.periods.never);
  const hasAutoLookup = Boolean(client?.props?.autoLookupPeriod !== constants.services.periods.never) || Boolean(collection?.autoLookupPeriod !== constants.services.periods.never);

  const [statusBreakdown] = useCollectionStatStatus(
    collection?.collectionId
  );

  const status = useMemo(() => {
    const status = {
      ...statusBreakdown,
      percentages: statusBreakdown ? [
        {
          label: 'Suggestions',
          value: Math.round((
            (+statusBreakdown.sources.total > 0 ? statusBreakdown.sources.finished / statusBreakdown.sources.total * 100 : 100) +
            (+statusBreakdown.suggestions.total > 0 ? statusBreakdown.suggestions.finished / statusBreakdown.suggestions.total * 100 : 100)
          ) / 2),
          msg: null,
          eta: ((statusBreakdown.sources.total - statusBreakdown.sources.finished) * constants.timing.eta.source) +
            ((statusBreakdown.suggestions.total - statusBreakdown.suggestions.finished) * constants.timing.eta.question.level1)
        },
        {
          label: 'Auto tags',
          value: Math.round(+statusBreakdown.tags.total > 0 ? statusBreakdown.tags.finished / statusBreakdown.tags.total * 100 : 100),
          msg: !hasAutoTags ? 'inactive' : null,
          eta: ((statusBreakdown.tags.total - statusBreakdown.tags.finished) * constants.timing.eta.question.level1)
        },
        {
          label: 'Auto lookup',
          value: Math.round(+statusBreakdown.lookup.total > 0 ? statusBreakdown.lookup.finished / statusBreakdown.lookup.total * 100 : 100),
          msg: !hasAutoLookup ? 'inactive' : null,
          eta: ((statusBreakdown.lookup.total - statusBreakdown.lookup.finished) * constants.timing.eta.lookup)
        }
      ] : [],
      processing: Boolean(statusBreakdown && (
        (+statusBreakdown.sources.total > 0 && (+statusBreakdown.sources.finished < +statusBreakdown.sources?.total)) ||
        (+statusBreakdown.suggestions.total > 0 && (+statusBreakdown.suggestions.finished < +statusBreakdown.suggestions?.total)) ||
        (+statusBreakdown.tags.total > 0 && (+statusBreakdown.tags.finished < +statusBreakdown.tags?.total)) ||
        (+statusBreakdown.lookup.total > 0 && (+statusBreakdown.lookup.finished < +statusBreakdown.lookup?.total))
      ))
    };

    status.progress = Math.round(status.percentages.reduce((s, p) => s + p.value, 0) / status.percentages.length);

    return status;
  }, [statusBreakdown, hasAutoTags, hasAutoLookup]);

  const openProfileEvent = useEffectEvent(profileProvider.openProfile);
  const [steps, todo] = useMemo(() => {
    let steps = [];
    if (collection) {
      steps = steps.concat([
        {
          label: 'Setup the basics',
          description: 'What are you looking for? Choose a description, topics and many more',
          completed: collection?.projectTopics?.length > 0,
          action: {
            label: 'Basics',
            onClick: (e) => {
              popperRef.current?.close?.(e);
              openProfileEvent?.({
                activeSection: 'basics',
                activeCard: !(collection?.projectTopics?.length > 0) ? 'basics' : null
              });
            }
          }
        },
        {
          label: 'Add categories',
          description: 'Organise your collection with categories and let Catalist auto-assign them for you',
          completed: collection?.tagGroups?.length > 0,
          action: {
            label: 'Categories',
            onClick: (e) => {
              popperRef.current?.close?.(e);
              openProfileEvent?.({
                activeSection: 'categories',
                onboarding: !(collection?.tagGroups?.length > 0)
              });
            }
          }
        },
        {
          label: 'Add sources',
          description: 'By adding sources and (optional) filters you can let Catalist search companies for you',
          completed: collection?.projectSources?.length > 0,
          action: {
            label: 'Sources',
            onClick: (e) => {
              popperRef.current?.close?.(e);
              openProfileEvent?.({
                activeSection: 'sources',
                onboarding: !(collection?.projectSources?.length > 0)
              });
            }
          }
        },
        {
          label: 'Add Fields',
          description: 'Add your own fields and let Catalist lookup the value for you',
          completed: +collection?.customFieldCount > 0,
          action: {
            label: 'Fields',
            onClick: (e) => {
              popperRef.current?.close?.(e);
              openProfileEvent?.({
                activeSection: 'fields',
                onboarding: !(+collection?.customFieldCount > 0)
              });
            }
          }
        },
        {
          label: 'Enable services',
          description: 'Turn on services like \'Auto tags\', \'Auto lookup\' or \'Enrichment\' and many more',
          completed: constants.data.services.filter((service) => {
            if (service.value === constants.services.types.autotags) {
              return Boolean(client?.props?.autotagPeriod !== constants.services.periods.never) || Boolean(collection?.autotagPeriod !== constants.services.periods.never);
            } else if (service.value === constants.services.types.autoLookup) {
              return Boolean(client?.props?.autoLookupPeriod !== constants.services.periods.never) || Boolean(collection?.autoLookupPeriod !== constants.services.periods.never);
            } else if (service.value === constants.services.types.linkedinEnrichment) {
              return Boolean(client?.props?.enrichmentPeriod !== constants.services.periods.never) || Boolean(collection?.enrichmentPeriod !== constants.services.periods.never);
            } else if (service.value === constants.services.types.companyInfoEnrichment) {
              return Boolean(client?.props?.companyInfoPeriod !== constants.services.periods.never) || Boolean(collection?.companyInfoPeriod !== constants.services.periods.never);
            } else {
              return false
            }
          }).length > 0,
          action: {
            label: 'Services',
            onClick: (e) => {
              popperRef.current?.close?.(e);
              openProfileEvent?.({activeSection: 'services'});
            }
          }
        }
      ]);
    }

    return [steps, steps.filter((s) => !s.completed).length];
  }, [collection, client, openProfileEvent]);

  const onboarding = useMemo(() => {
    const autoOpen = collection?.newInCollection?.find((c) => c.scope === constants.collection.scopes.inside)?.total === 0;

    const renderTooltip = () => {
      return !status.processing ? 'Setup your collection' : <ProgressTooltip progress={status.percentages} />
    }

    const renderIcon = () => {
      return !status.processing ? TaskAlt :
        <Tooltip title={renderTooltip()}
                 placement="left"
                 enterDelay={0} leaveDelay={0}>
          <CircularProgress className="CollectionOnboarding-progress"
                            showPercentage={true}
                            value={status.progress}
                            color="white"
                            variant="determinate"
                            TypographyProps={{
                              variant: 'caption',
                              color: 'white'
                            }}/>
        </Tooltip>
    }

    return {
      tooltip: renderTooltip(),
      icon: renderIcon(),
      color: 'primary',
      auth: utils.createAuth({attribute: 'collection.update', meta: {collection}}),
      badge: todo > 0 ? <Badge badgeContent={todo} color="error"/> : null,
      popper: <Onboarding title="How to setup your collection"
                          steps={steps}/>,
      ActionFabProps: {
        autoOpen: (todo > 0 && autoOpen),
        TooltipProps: {
          placement: 'left',
          ...(status.processing ? {enterDelay: 0} : {})
        },
        DropDownPopperProps: {
          ref: popperRef,
          autoFocus: false,
          ContextPopperProps: {
            elevation: 2,
            placement: 'top-end',
            outlined: false,
            reverseShadow: false,
            fixed: true,
            density: 'densest',
            offset: [
              0,
              -utils.rem2Pixel(innerProps.theme.layout('4sp'), innerProps.theme.typography.htmlFontSize)
            ],
            TransitionComponent: Slide,
            TransitionProps: {
              direction: 'left'
            }
          }
        }
      }
    }
  }, [collection, steps, todo, status, innerProps.theme]);

  if (!isLoading && (todo > 0 || showEmpty)) {
    return <StyledCollectionOnboarding ref={ref} {...innerProps}>
      <ActionFab action={onboarding} isLoading={isLoading}/>
    </StyledCollectionOnboarding>
  }
}));

CollectionOnboarding.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  collection: PropTypes.object,
  showEmpty: PropTypes.bool,
  isLoading: PropTypes.bool
};

CollectionOnboarding.defaultProps = {
};

export default CollectionOnboarding;
