import React, {useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledTableBar from 'components/organisms/Bars/TableBar/TableBar.styles';
import constants from 'helpers/constants';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Search from '@mui/icons-material/Search';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';
import Clear from '@mui/icons-material/Clear';
import ViewColumnSharp from '@mui/icons-material/ViewColumnSharp';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import FilterList from '@mui/icons-material/FilterList';
import Badge from 'components/atoms/Badges/Badge/Badge';
import Tooltip from 'components/atoms/Tooltips/Tooltip/Tooltip';
import {Span} from 'components/atoms/Text/Typography/Typography';
import Save from '@mui/icons-material/Save';
import dom from 'helpers/dom';
import {ChatOutlined} from '@mui/icons-material';

const TableBar = React.forwardRef((props, ref) => {
  const {
    searchField,
    chatAction,
    filterAction,
    columnAction,
    saveAction,
    hideChat,
    hideFilter,
    hideColumn,
    hideSave,
    ...innerProps
  } = useComponentProps(props, 'TableBar');

  const tableProvider = useTable();

  const filterCount = utils.filterCount(tableProvider.listState.filter);

  const handleSearch = (field, value) => {
    tableProvider.listState?.setSearch?.(value);
  }

  const searchFieldRef = useRef(null);
  const searchFields = useMemo(() => ([{
    name: 'search',
    label: 'Search',
    inlineLabel: 'search',
    type: constants.formFieldTypes.text,
    validation: constants.formFieldValidationTypes.text,
    initial: tableProvider.listState?.search,
    FormFieldProps: {
      ref: searchFieldRef,
      hiddenLabel: true,
      size: 'smaller',
      debounce: constants.debounce.search,
      radius: 'round'
    },
    prefix: <Icon icon={Search} />,
    postfix: tableProvider.listState?.search?.length > 0 ? <ActionIconButton action={{
      icon: <Icon icon={Clear}/>,
      onClick: () => {
        searchFieldRef.current?.clear();
        dom.focusElement(searchFieldRef.current);
      }
    }} /> : null,
    ...searchField
  }]), [searchField, tableProvider.listState?.search]);

  const toggleColumnsEvent = useEffectEvent(tableProvider.toggleColumns);
  const columnActionMemo = useMemo(() => ({
    onClick: () => {
      toggleColumnsEvent?.();
    },
    label: 'Columns',
    icon: ViewColumnSharp,
    ActionButtonProps: {
      variant: 'outlined',
      plainBorder: true,
      color: 'inherit',
      size: 'medium'
    },
    ...columnAction
  }), [columnAction, toggleColumnsEvent]);

  const chatActionMemo = useMemo(() => ({
    label: 'Chat',
    icon: ChatOutlined,
    ActionButtonProps: {
      color: 'secondary',
      variant: 'outlined',
      plainBorder: true,
      radius: 'round'
    },
    ButtonProps: {
      disabled: !(tableProvider.list?.meta?.resultsCount > 0)
    },
    ...chatAction
  }), [chatAction, tableProvider.list?.meta?.resultsCount]);

  const setFilterEvent = useEffectEvent(tableProvider.listState.setFilter);
  const toggleFiltersEvent = useEffectEvent(tableProvider.toggleFilters);
  const filterActionMemo = useMemo(() => ({
    onClick: () => {
      toggleFiltersEvent?.();
    },
    label: 'Filters',
    icon: FilterList,
    badge: filterCount > 0 ? <Badge badgeContent={<Tooltip title={'Clear'}>
      <Span onClick={(e) => {
              setFilterEvent?.([]);
              e.preventDefault();
              e.stopPropagation();
            }}
            onMouseOver={() => console.log('hover')}
            onMouseDown={(e) => e.stopPropagation()}>
        {filterCount}
      </Span>
    </Tooltip>} clickable={true} color="error"/> : null,
    ActionButtonProps: {
      color: 'secondary',
      variant: 'outlined',
      plainBorder: true,
      radius: 'round'
    },
    ...filterAction
  }), [filterAction, filterCount, setFilterEvent, toggleFiltersEvent]);

  const saveActionMemo = useMemo(() => ({
    onClick: () => {
    },
    label: 'Save',
    icon: Save,
    ActionButtonProps: {
      variant: 'outlined',
      color: 'inherit',
      radius: 'round'
    },
    ...saveAction
  }), [saveAction]);

  const resultsCount = utils.formatNumber(tableProvider.list?.meta?.resultsCount ?? 0);

  innerProps.title = innerProps.title ?? `${resultsCount} results`;
  innerProps.rightFields = innerProps.rightFields ?? searchFields;
  innerProps.onRightChange = innerProps.onRightChange ?? handleSearch;
  innerProps.rightActions = innerProps.rightActions ?? [
    hideChat ? null : chatActionMemo,
    hideFilter ? null : filterActionMemo,
    hideColumn ? null : columnActionMemo,
    hideSave ? null : saveActionMemo,
  ].filter((_) => (_));
  innerProps.isLoading = innerProps.isLoading ?? tableProvider.isLoading();

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledTableBar ref={ref} {...innerProps}/>
});

TableBar.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  searchField: PropTypes.object,
  filterAction: PropTypes.object,
  columnAction: PropTypes.object,
  saveAction: PropTypes.object,
  hideChat: PropTypes.bool,
  hideFilter: PropTypes.bool,
  hideColumn: PropTypes.bool,
  hideSave: PropTypes.bool
};

TableBar.defaultProps = {
  hideSave: true
};

export default TableBar;
