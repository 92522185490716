import React from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import Box from 'components/atoms/Layout/Box/Box';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Typography, {H6} from 'components/atoms/Text/Typography/Typography';
import constants from 'helpers/constants';
import StyledTagGroupCard from 'components/organisms/Cards/TagGroupCard/TagGroupCard.styles';
import Chip from 'components/atoms/Chips/Chip/Chip';
import ChipList from 'components/atoms/Chips/ChipList/ChipList';
import {Dot} from 'assets/icons';

const TagGroupCard = React.forwardRef((props, ref) => {
  const {
    tagGroup,
    hasAutoTags,
    isLoading,
    variant,
    ...innerProps
  } = useComponentProps(props, 'TagGroupCard');

  const compact = variant === 'compact';

  const renderDetails = () => {
    return <Box className="TagGroupCard-details">
      <Box className="TagGroupCard-detail">
        <Typography className="TagGroupCard-detail-label"
                    isLoading={isLoading}
                    variant="caption">Add points</Typography>
        <Typography className="TagGroupCard-detail-value"
                    isLoading={isLoading}
                    variant="caption">{tagGroup?.hasPoints ? 'Yes' : 'No'}</Typography>
      </Box>
      <Box className="TagGroupCard-detail">
        <Typography className="TagGroupCard-detail-label"
                    isLoading={isLoading}
                    variant="caption">Multiselect</Typography>
        <Typography className="TagGroupCard-detail-value"
                    isLoading={isLoading}
                    variant="caption">{tagGroup?.multiselect ? 'Yes' : 'No'}</Typography>
      </Box>
      <Box className="TagGroupCard-detail">
        <Typography className="TagGroupCard-detail-label"
                    isLoading={isLoading}
                    variant="caption">Autoselect</Typography>
        {(tagGroup?.autoTagType === constants.collection.autoTagTypes.byKeywords) ?
          <Chip className="TagGroupCard-detail-chip"
                tooltip={!hasAutoTags ? 'Auto tags service is not active' : ''}
                showTooltip={!hasAutoTags}
                isLoading={isLoading}
                size="small" variant="filled"
                label="By keywords" color={hasAutoTags ? 'success' : 'warning'} /> : (
            (tagGroup?.autoTagType === constants.collection.autoTagTypes.byAI) ?
              <Chip className="TagGroupCard-detail-chip"
                    tooltip={!hasAutoTags ? 'Auto tags service is not active' : ''}
                    showTooltip={!hasAutoTags}
                    isLoading={isLoading}
                    size="small" variant="filled"
                    label="By AI" color={hasAutoTags ? 'success' : 'warning'} /> : (
                <Chip className="TagGroupCard-detail-chip"
                      showTooltip={false}
                      isLoading={isLoading}
                      size="small" variant="filled"
                      label="Not active" />
              )
          )}
      </Box>
    </Box>
  }

  const renderTags = () => {
    const tags = !isLoading ? tagGroup?.tags : (new Array(Math.max(2, Math.ceil(Math.random() * 8))))
      .fill({value: utils.randomString(8, 16)});

    return <Box className="TagGroupCard-tags">
      <ChipList variant="compact"
                drawFromStart={true}>
        {tags?.map((t, idx) => {
          return <Chip key={idx}
                       size="small"
                       label={t.value}
                       tooltip={t.description}
                       TooltipProps={t.description ? {enterDelay: 0} : {}}
                       variant="outlined"
                       isLoading={isLoading}
                       icon={<Icon icon={Dot} size="smaller" color={tagGroup?.color || 'secondary'}/>}
                       color={tagGroup?.color || 'secondary'}/>
        })}
      </ChipList>
    </Box>
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledTagGroupCard ref={ref} {...innerProps}>
    <Box className="TagGroupCard-content">
      <Box className="TagGroupCard-info">
        <H6 className="TagGroupCard-title"
            showTooltip={true}
            min={8} max={20}
            isLoading={isLoading}>{tagGroup?.name}</H6>
        {!compact ? renderDetails() : null}
      </Box>
    </Box>
    {renderTags()}
  </StyledTagGroupCard>
});

TagGroupCard.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  tagGroup: PropTypes.object,
  hasAutoTags: PropTypes.bool,
  isLoading: PropTypes.bool,
  variant: PropTypes.oneOfType([PropTypes.oneOf(['standard', 'compact']), PropTypes.string])
};

TagGroupCard.defaultProps = {
  variant: 'standard'
};

export default TagGroupCard;
