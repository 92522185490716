import utils from 'helpers/utils';
import {useServiceStatus} from 'stores/hooks/service';
import {useServiceApiMutation, useServiceApiQuery} from 'stores/hooks/query';
import constants from 'helpers/constants';

const path = utils.servicePath(import.meta.url);

export function useEntityStatus () {
  return useServiceStatus(path);
}

export function useEntityList ({clientId, search, filter, sort, page, pageSize, ...rest}, queryOptions = {}) {
  return useServiceApiQuery(path, 'list',
    {clientId, search, filter, sort, page, pageSize, ...rest},
    {overridePath: 'clients/:clientId/entities', ...queryOptions});
}

// lookup is default list function also for refreshing entities
export function useEntityLookup ({name, website, entityIds, ...rest}, queryOptions = {}) {
  return useServiceApiQuery(path, 'list', {name, website, entityIds, ...rest},
    {overridePath: 'entities/lookup?minimal=false', ...queryOptions});
}

// byName is default list function also for refreshing entities
export function useEntityByName ({name, website, ...rest}, queryOptions = {}) {
  return useServiceApiQuery(path, 'list', {name, hostname: website, ...rest},
    {overridePath: 'entities/byName', ...queryOptions});
}

// byNameEs is default list function also for refreshing entities
export function useEntityByNameES ({name, website, ...rest}, queryOptions = {}) {
  return useServiceApiQuery(path, 'list', {name, hostname: website, ...rest},
    {overridePath: 'entities/byNameES', ...queryOptions});
}

// history also returns list like entities they will land in the same cache
export function useEntityHistory ({page, pageSize, ...rest}, queryOptions = {}) {
  return useServiceApiQuery(path, 'list', {page, pageSize, ...rest},
    {overridePath: 'entities/history', ...queryOptions});
}

// quicksearch is a post function but also returns list like entities they will land in the same cache
export function useEntityQuickSearch ({search, depth, ...rest}, queryOptions = {}) {
  const filters = {
    query: search
  };

  return useServiceApiQuery(path, 'post', {filters, depth, minimal: false, ...rest}, {
    overridePath: 'entities/quicksearch?minimal=false',
    overrideDataType: constants.dataTypes.list,
    ...queryOptions
  });
}

export function useEntitySearch ({query, page, pageSize, ...rest}, queryOptions = {}) {
  return useServiceApiQuery(path, 'post', {
    query,
    $httpParams: {
      page,
      pageSize,
      ...rest
    }
  }, {
    overridePath: 'search/query',
    overrideDataType: constants.dataTypes.list,
    ...queryOptions
  });
}

export function useEntityGet ({entityId}, queryOptions = {}) {
  return useServiceApiQuery(path, 'get', {entityId}, queryOptions);
}

export function useEntityAdd (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    refetchContext: true,
    ...mutationOptions
  });
}

export function useEntityUpdate (mutationOptions = {}) {
  return useServiceApiMutation(path, 'patch', {}, mutationOptions);
}

export function useEntityDelete (mutationOptions = {}) {
  return useServiceApiMutation(path, 'delete', {}, {
    refetchContext: true,
    ...mutationOptions
  });
}

export function useEntityEnrich (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    overridePath: 'entities/:entityId/enrich',
    ...mutationOptions
  });
}

export function useEntityMerge (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    overridePath: 'entities/:entityId/merge/:mergedId',
    refetchContext: true,
    refetchChildren: true,
    ...mutationOptions
  });
}

// bulk
export function useEntityAddToCollectionBulk (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    refetchContext: true,
    overridePath: 'entities/bulk/addToCollection',
    ...mutationOptions
  });
}

export function useEntityRejectFromCollectionBulk (mutationOptions = {}) {
  return useServiceApiMutation(path, 'post', {}, {
    refetchContext: true,
    overridePath: 'entities/bulk/rejectFromCollection',
    ...mutationOptions
  });
}

// dataType = other function(s):
// - are misplaced and should be in utilities like service,
// - in this case they are entities related therefore it rests here for convenience
// - the path will be the key unless the items have the store - entity - key
// - data will never mix with cached list / entity but can be refreshed if the data is entity like
// Examples (
//  utilities like /filterOptions,
//  lists with different data item(s) e.g. listEntityNames for example,
//  things that don't have direct get/put/patch endpoints
// )
export function useEntityFiltersGet (queryOptions = {}) {
  return useServiceApiQuery(path, 'get', null, {
    overridePath: 'entities/filters',
    overrideDataType: constants.dataTypes.other,
    ...queryOptions
  });
}

export function useEntitySearchGoogle ({query, ...rest}, queryOptions = {}) {
  return useServiceApiQuery(path, 'post', {query, ...rest}, {
    overridePath: 'entities/searchGoogle',
    overrideDataType: constants.dataTypes.other,
    ...queryOptions
  });
}

export function useEntitySearchCocNumber ({query, ...rest}, queryOptions = {}) {
  return useServiceApiQuery(path, 'post', {query, ...rest}, {
    overridePath: 'entities/searchCocNumber',
    overrideDataType: constants.dataTypes.other,
    ...queryOptions
  });
}

export function useEntityMergeAttempt ({entityId, mergedId}, queryOptions = {}) {
  return useServiceApiQuery(path, 'get', {entityId, mergedId}, {
    overridePath: 'entities/:entityId/mergeAttempt/:mergedId',
    overrideDataType: constants.dataTypes.other,
    ...queryOptions
  });
}
