import React, {useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';

import {useProfile} from 'components/organisms/Providers/ProfileProvider/ProfileProvider';
import StyledCollectionProfile from 'components/organisms/Profiles/CollectionProfile/CollectionProfile.styles';
import CollectionProfileCardContent
  from 'components/organisms/Cards/CollectionProfileCardContent/CollectionProfileCardContent';
import profile from 'helpers/profile';
import CollectionTagGroupProfileCardContent
  from 'components/organisms/Cards/CollectionTagGroupProfileCardContent/CollectionTagGroupProfileCardContent';
import CollectionTagGroupProfileCard
  from 'components/organisms/Cards/CollectionTagGroupProfileCard/CollectionTagGroupProfileCard';
import constants from 'helpers/constants';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import CollectionSourceProfileCardContent
  from 'components/organisms/Cards/CollectionSourceProfileCardContent/CollectionSourceProfileCardContent';
import CollectionSourceProfileCard
  from 'components/organisms/Cards/CollectionSourceProfileCard/CollectionSourcesProfileCard';
import CollectionServiceProfileCard
  from 'components/organisms/Cards/CollectionServiceProfileCard/CollectionServiceProfileCard';
import CollectionServiceProfileCardContent
  from 'components/organisms/Cards/CollectionServiceProfileCardContent/CollectionServiceProfileCardContent';
import logger from 'helpers/logger';
import CollectionFieldProfileCard
  from 'components/organisms/Cards/CollectionFieldProfileCard/CollectionFieldProfileCard';
import CollectionFieldProfileCardContent
  from 'components/organisms/Cards/CollectionFieldProfileCardContent/CollectionFieldProfileCardContent';
import Add from '@mui/icons-material/Add';
import Icon from 'components/atoms/Icons/Icon/Icon';

const CollectionProfile = React.forwardRef((props, ref) => {
  const {
    onCanUpdate,
    forceEditNew,
    onSubmit,
    onPatch,
    onMove,
    fieldData,
    ...innerProps
  } = useComponentProps(props, 'CollectionProfile');

  const profileProvider = useProfile();
  // profile is used for universe collection as well
  const collection = utils.isDefined(profileProvider.context?.data?.collectionId) ? profileProvider.context?.data : profileProvider.data?.data;
  const cardDefinitions = profileProvider.cardDefinitions;

  const authorize = useAuthorize();

  const snackbar = useSnackbar();

  const updateEvent = useEffectEvent(profileProvider.updaters?.updateCollection ?? profileProvider.updaters?.updateData);
  const updateSourceEvent = useEffectEvent(profileProvider.updaters?.updateSource);
  const createTagGroupEvent = useEffectEvent(profileProvider.updaters?.createTagGroup);
  const updateTagGroupEvent = useEffectEvent(profileProvider.updaters?.updateTagGroup);
  const deleteTagGroupEvent = useEffectEvent(profileProvider.updaters?.deleteTagGroup);
  const createCustomFieldEvent = useEffectEvent(profileProvider.updaters?.createCustomField);
  const updateCustomFieldEvent = useEffectEvent(profileProvider.updaters?.updateCustomField);
  const deleteCustomFieldEvent = useEffectEvent(profileProvider.updaters?.deleteCustomField);
  const toggleVisibilityEvent = useEffectEvent(profileProvider.updaters?.toggleVisibility);
  const onPatchEvent = useEffectEvent(onPatch);
  const handlePatch = useCallback((collection, field, value, onSuccess, onError) => {
    if (onPatchEvent) {
      onPatchEvent?.(field, value, onSuccess, onError);
    } else {
      const promises = [];

      if (utils.camelcase(field.name) === 'visibility') {
        promises.push(toggleVisibilityEvent?.(collection, value));
      } else if (field.name.startsWith('tagGroup')) {
        if (+value?.groupId > 0) {
          promises.push(updateTagGroupEvent?.(collection, value));
        } else {
          promises.push(deleteTagGroupEvent?.({groupId: field.name.split('-')[1]}));
        }
      } else if (field.name.startsWith('customField')) {
        if (+value?.fieldId > 0) {
          promises.push(updateCustomFieldEvent?.(field.customField, value));
        } else {
          promises.push(deleteCustomFieldEvent?.({fieldId: field.name.split('-')[1]}));
        }
      } else if (field.name.startsWith('source')) {
        promises.push(updateSourceEvent?.(value));
      } else if (field.name === 'promise') {
        promises.push(value);
      } else {
        promises.push(updateEvent?.(collection, {[field.name]: value}));
      }

      if (promises.length > 0) {
        Promise.all(promises)
          .then(() => {
            onSuccess?.();
          }).catch((err) => {
            logger.trace('Collection save failed', err);
            onError?.();
          });
      } else {
        onSuccess?.();
      }
    }
  }, [
    updateEvent, onPatchEvent, toggleVisibilityEvent,
    updateTagGroupEvent, deleteTagGroupEvent,
    updateCustomFieldEvent, deleteCustomFieldEvent,
    updateSourceEvent
  ]);

  const onSubmitEvent = useEffectEvent(onSubmit);
  const handleSubmit = useCallback((collection, values, fields, actions, onSuccess, onError) => {
    if (onSubmitEvent) {
      onSubmitEvent?.(values, fields, actions, onSuccess, onError);
    } else {
      const changes = {};
      let visibility, tagGroup, source, customField, promise;

      Object.keys(values).forEach((k) => {
        const field = fields.find((f) => f.name === k);
        if (field) {
          if (utils.camelcase(field.name) === 'visibility') {
            if (collection.visibility !== values[k]) {
              visibility = values[k];
            }
          } else if (field.name.startsWith('tagGroup')) {
            tagGroup = values[k] ?? {groupId: field.name.split('-')[1], delete: true};
          } else if (field.name.startsWith('source')) {
            source = values[k];
          } else if (field.name.startsWith('customField')) {
            customField = values[k] ?? {fieldId: field.name.split('-')[1], delete: true};
          } else {
            changes[k] = values[k];
          }
        } else if (k === 'promise') {
          promise = values[k];
        }
      });

      const promises = [];
      if (utils.isDefined(visibility)) {
        promises.push(toggleVisibilityEvent?.(collection, visibility));
      }
      if (utils.isDefined(tagGroup)) {
        if (tagGroup.delete) {
          promises.push(deleteTagGroupEvent?.(tagGroup));
        } else if (+tagGroup.groupId > 0) {
          promises.push(updateTagGroupEvent?.(collection, tagGroup));
        } else {
          promises.push(createTagGroupEvent?.(tagGroup));
        }
      }
      if (utils.isDefined(customField)) {
        if (customField.delete) {
          promises.push(deleteCustomFieldEvent?.(customField));
        } else if (+customField.fieldId > 0) {
          promises.push(updateCustomFieldEvent?.(customField.original, utils.filterObject(customField, 'original')));
        } else {
          promises.push(createCustomFieldEvent?.(customField));
        }
      }
      if (utils.isDefined(source)) {
        promises.push(updateSourceEvent?.(source));
      }
      if (!utils.isEmpty(changes)) {
        promises.push(updateEvent?.(collection, changes));
      }
      if (utils.isDefined(promise)) {
        promises.push(promise);
      }

      if (promises.length > 0) {
        Promise.all(promises)
          .then(() => {
            onSuccess?.();
          }).catch((err) => {
            logger.trace('Collection save failed', err);
            onError?.();
          });
      } else {
        onSuccess?.();
      }
    }
  }, [
    onSubmitEvent, updateEvent, toggleVisibilityEvent, updateSourceEvent,
    deleteTagGroupEvent, updateTagGroupEvent, createTagGroupEvent,
    deleteCustomFieldEvent, updateCustomFieldEvent, createCustomFieldEvent
  ]);

  const moveTagGroupEvent = useEffectEvent(profileProvider.updaters?.moveTagGroup);
  const onMoveEvent = useEffectEvent(onMove);
  const handleMove = useCallback((collection, card, containerId, position, onSuccess, onError) => {
    if (onMoveEvent) {
      onMoveEvent?.(card, containerId, position, onSuccess, onError);
    } else {
      moveTagGroupEvent?.({groupId: card.data.groupId, pos: position + 1})
        .catch(() => {
          snackbar.show('Moving category failed', null,
            {color: 'error', autoHideDuration: constants.delay.error});
          onError?.();
        });
    }
  }, [moveTagGroupEvent, snackbar, onMoveEvent]);

  const onCanUpdateEvent = useEffectEvent(onCanUpdate);
  const cardsMemo = useMemo(() => {
    if (cardDefinitions) {
      return cardDefinitions.map((cardDef) => {
        const canEditCard = (collection) => {
          const canEditCollection = (onCanUpdateEvent ? onCanUpdateEvent?.(collection) : true) &&
            authorize({attribute: 'collection.update', meta: {collection}});

          return canEditCollection && !(cardDef.readOnly ?? false) && authorize({...cardDef.auth?.update, meta: {...cardDef.auth?.update?.meta, collection}});
        }

        const fields = cardDef.fields?.map((field) => {
          const canEditField = (collection) => {
            return onCanUpdateEvent ? onCanUpdateEvent?.(collection, field) : (field.auth?.update ?
              authorize({...field.auth?.update, meta: {...field.auth?.update?.meta, collection}}) : true);
          }

          return {
            ...field,
            editable: ({data}) => canEditField(data)
          }
        });

        if (cardDef.name.startsWith('tagGroup')) {
          const isAddNew = !(cardDef.data?.groupId > 0);
          return {
            ...cardDef,
            editable: ({profile}) => canEditCard(profile.data),
            draggable: !isAddNew,
            droppable: !isAddNew,
            isEditing: () => {
              return (isAddNew && forceEditNew);
            },
            toggle: (isAddNew && forceEditNew),
            SaveButtonProps: isAddNew ? {
              color: 'success',
              startIcon: <Icon icon={Add}/>,
              children: 'Create category',
            } : null,
            contents: [
              {
                id: 1,
                fields,
                Content: ({ref, profile, card, data, content, fieldData, isDialog, onPatch, onSubmit, onValidating}) => {
                  return <CollectionTagGroupProfileCardContent ref={ref}
                                                               card={card}
                                                               content={content}
                                                               collection={profile?.data}
                                                               isDialog={isDialog}
                                                               fieldData={fieldData}
                                                               tagGroup={data}
                                                               onPatch={onPatch}
                                                               onSubmit={onSubmit}
                                                               onValidating={onValidating}/>
                }
              }
            ],
            Card: ({ref, profile, card, data, action, fieldData, onHide, isDialog, renderedContent}) => {
              return <CollectionTagGroupProfileCard ref={ref}
                                                    card={card}
                                                    onHide={onHide}
                                                    action={action}
                                                    collection={profile?.data}
                                                    tagGroup={data}
                                                    fieldData={fieldData}
                                                    isDialog={isDialog}
                                                    renderedContent={renderedContent}/>
            },
            onSubmit: handleSubmit,
            onPatch: handlePatch,
            onMove: handleMove
          };
        } else if (cardDef.name.startsWith('source')) {
          const isAddNew = !utils.isDefined(cardDef.data?.sourceId);

          let sourceInfo = cardDef.data, sourceData;
          if (!isAddNew) {
            sourceData = fieldData?.sources?.find((s) => +s.sourceId === +cardDef.data?.sourceId);
            sourceInfo = {
              ...cardDef.data,
              ...sourceData
            };

            if (sourceInfo?.type === constants.sources.types.suggestions) {
              sourceInfo.name = constants.data.lookup('sourceTypes', constants.sources.types.suggestions).label;
            }
            sourceInfo.title = sourceInfo.name;
          }

          return {
            ...cardDef,
            data: sourceInfo,
            title: sourceInfo?.title,
            editable: ({profile}) => canEditCard(profile.data),
            toggle: (isAddNew && forceEditNew),
            SaveButtonProps: isAddNew ? {
              color: 'success',
              startIcon: <Icon icon={Add}/>,
              children: 'Create source'
            } : null,
            contents: [
              {
                id: 1,
                fields,
                Content: ({ref, profile, card, data, content, fieldData, isDialog, renderedLoader, onPatch, onSubmit, onValidating}) => {
                  return <CollectionSourceProfileCardContent ref={ref}
                                                             card={card}
                                                             content={content}
                                                             collection={profile?.data}
                                                             isDialog={isDialog}
                                                             fieldData={fieldData}
                                                             source={data}
                                                             renderedLoader={renderedLoader}
                                                             onPatch={onPatch}
                                                             onSubmit={onSubmit}
                                                             onValidating={onValidating}/>
                }
              }
            ],
            Card: ({ref, profile, card, data, action, fieldData, onHide, isDialog, renderedContent}) => {
              return <CollectionSourceProfileCard ref={ref}
                                                  card={card}
                                                  onHide={onHide}
                                                  action={action}
                                                  collection={profile?.data}
                                                  source={data}
                                                  fieldData={fieldData}
                                                  isDialog={isDialog}
                                                  renderedContent={renderedContent}/>
            },
            onSubmit: handleSubmit,
            onPatch: handlePatch,
            onMove: handleMove,
            hidden: ({card}) => {
              return !isAddNew && !card.data.upload && (+card.data.sourceId > 0) && (fieldData?.sources && !sourceData);
            }
          };
        } else if (cardDef.name.startsWith('customField')) {
          const isAddNew = !utils.isDefined(cardDef.data?.fieldId);

          return {
            ...cardDef,
            title: !isAddNew ? cardDef.data?.label : 'Add field',
            editable: ({profile}) => canEditCard(profile.data),
            toggle: (isAddNew && forceEditNew),
            SaveButtonProps: isAddNew ? {
              color: 'success',
              startIcon: <Icon icon={Add}/>,
              children: 'Create field',
            } : null,
            contents: [
              {
                id: 1,
                fields,
                Content: ({ref, profile, card, data, content, fieldData, isDialog, renderedLoader, onPatch, onSubmit, onValidating}) => {
                  return <CollectionFieldProfileCardContent ref={ref}
                                                            card={card}
                                                            content={content}
                                                            collection={profile?.data}
                                                            isDialog={isDialog}
                                                            fieldData={fieldData}
                                                            field={data}
                                                            renderedLoader={renderedLoader}
                                                            onPatch={onPatch}
                                                            onSubmit={onSubmit}
                                                            onValidating={onValidating}/>
                }
              }
            ],
            Card: ({ref, profile, card, data, action, fieldData, onHide, isDialog, renderedContent}) => {
              return <CollectionFieldProfileCard ref={ref}
                                                 card={card}
                                                 onHide={onHide}
                                                 action={action}
                                                 collection={profile?.data}
                                                 field={data}
                                                 fieldData={fieldData}
                                                 isDialog={isDialog}
                                                 renderedContent={renderedContent}/>
            },
            onSubmit: handleSubmit,
            onPatch: handlePatch,
            onMove: handleMove
          };
        } else if (cardDef.name.startsWith('service')) {
          return {
            ...cardDef,
            editable: ({profile}) => canEditCard(profile.data),
            contents: [
              {
                id: 1,
                fields,
                Content: ({ref, profile, card, data, content, fieldData, isDialog, onPatch, onSubmit, onValidating}) => {
                  return <CollectionServiceProfileCardContent ref={ref}
                                                              card={card}
                                                              content={content}
                                                              collection={profile?.data}
                                                              isDialog={isDialog}
                                                              fieldData={fieldData}
                                                              service={data}
                                                              onPatch={onPatch}
                                                              onSubmit={onSubmit}
                                                              onValidating={onValidating}/>
                }
              }
            ],
            Card: ({ref, profile, card, data, isDialog, renderedHeader, renderedContent}) => {
              return <CollectionServiceProfileCard ref={ref}
                                                   card={card}
                                                   collection={profile?.data}
                                                   service={data}
                                                   isDialog={isDialog}
                                                   renderedHeader={renderedHeader}
                                                   renderedContent={renderedContent}/>
            },
            onSubmit: handleSubmit,
            onPatch: handlePatch,
            onMove: handleMove
          };
        } else {
          const contents = profile.fields2Contents(fields, [], {ContentComponent: CollectionProfileCardContent});

          if (contents.length > 0) {
            return {
              ...cardDef,
              editable: ({profile}) => canEditCard(profile.data),
              contents: contents,
              onPatch: handlePatch,
              onSubmit: handleSubmit
            }
          } else {
            return null;
          }
        }
      }).filter((_) => (_));
    } else {
      return null;
    }
  }, [cardDefinitions, authorize, forceEditNew, fieldData?.sources,
    handlePatch, handleSubmit, handleMove, onCanUpdateEvent]);

  return <StyledCollectionProfile ref={ref} {...innerProps}
                                  data={collection}
                                  fieldData={fieldData}
                                  cards={cardsMemo}/>
});

CollectionProfile.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  onCanUpdate: PropTypes.func,
  forceEditNew: PropTypes.bool,
  onSubmit: PropTypes.func,
  onPatch: PropTypes.func,
  onMove: PropTypes.func,
  fieldData: PropTypes.object
};

CollectionProfile.defaultProps = {
};

export default CollectionProfile;
