import React, {useImperativeHandle, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent, useListState} from 'helpers/hooks/utils';
import StyledEntitiesFinalizeWizardContent
  from 'components/organisms/WizardContent/EntitiesFinalizeWizardContent/EntitiesFinalizeWizardContent.styles';
import Box from 'components/atoms/Layout/Box/Box';
import {H6} from 'components/atoms/Text/Typography/Typography';
import Table from 'components/organisms/Tables/Table/Table';
import constants from 'helpers/constants';
import utils from 'helpers/utils';
import FieldTableCell from 'components/molecules/TableCells/FieldTableCell/FieldTableCell';
import {useEntitySettings} from 'services/entity/entity.utils';

const EntitiesFinalizeWizardContent = React.forwardRef((props, ref) => {
  const {
    wizard,
    step,
    isSource,
    onComplete,
    onError,
    onDirty,
    ...innerProps
  } = useComponentProps(props, 'EntitiesFinalizeWizardContent');

  const innerRef = useRef(null);

  const data = wizard.data?.[wizard.dataKey];
  const fields = data.fields;
  const total = data.total;
  const example = data.example;
  const collectionId = data.params.collectionId;

  const [entityFieldData] = useEntitySettings(collectionId);

  const options = useMemo(() => ({
    listState: {
      options: {
        name: `entitiesFinalize`,
      }
    }
  }), []);

  const listState = useListState(options.listState?.initial, options.listState?.options);

  const onCompleteEvent = useEffectEvent(onComplete);
  const finalizeColumnsWizardContent = useMemo(() => ({
    refs: {
      ref: innerRef,
    },
    submit: () => {
      onCompleteEvent?.(Boolean(step?.resetNextSteps));
    }
  }), [
    onCompleteEvent,
    step?.resetNextSteps
  ]);

  useImperativeHandle(ref, () => finalizeColumnsWizardContent);

  const columnDefinitions = useMemo(() => {
    return example.reduce((acc, item) => {
      const addField = (key, group) => {
        const customField = fields.find((field) => field.name === key);
        const fieldDef = constants.upload.fieldDefinition.lookup(utils.camelcase(key));

        if (!acc.some((field) => field.name === (fieldDef?.name ?? key))) {
          acc.push({
            id: fieldDef?.name ?? key,
            name: fieldDef?.name ?? key,
            header: fieldDef?.label ?? customField?.label,
            field: {
              ...fieldDef,
              name: fieldDef?.name ?? key,
              entity: 'entity'
            },
            group: group
          });
        }
      }

      Object.keys(item).forEach((key) => {
        if (key === 'links') {
          Object.keys(item[key]).forEach((link) => {
            addField(link, 'links');
          });
        } else {
          addField(key);
        }
      });
      return acc;
    }, []);
  }, [example, fields]);

  const columnsMemo = useMemo(() => {
    const columns = [];

    if (columnDefinitions) {
      columnDefinitions.forEach((columnDef) => {
        columns.push({
          ...columnDef,
          size: 100,
          minSize: 100,
          Cell: ({cell}) => {
            const data = columnDef.group ? cell.row.original[columnDef.group] : cell.row.original;
            const field = utils.initializeFormFields([columnDef.field], data)[0];
            return <FieldTableCell fields={[field]} fieldData={entityFieldData}/>
          }
        });
      });
    }

    return columns;
  }, [columnDefinitions, entityFieldData]);

  const entities = useMemo(() => {
    const examples = utils.clone(example, true);
    examples.forEach((example) => {
      if (example.finance_history) {
        example.finance_history = utils.toArray(example.finance_history).reduce((s, year) => {
          if (s) {
            s += ', ';
          }
          s += `${year.year} - `;

          s += Object.keys(year).reduce((s, k) => {
            if (k !== 'year') {
              if (s) {
                s += ', ';
              }
              s += `${k}: ${utils.formatNumber(year[k])}`;
            }
            return s;
          }, '');

          return s;
        }, '');
      }
    });
    return utils.camelcaseEx(examples);
  }, [example]);

  const renderSubtitle = () => {
    let subTitle = `All set! ${total ?? 0} compan${total > 1 ? 'ies are' : 'y is'} ready to be added to the collection.`;
    if (isSource) {
      subTitle = `All set! ${total ?? 0} compan${total > 1 ? 'ies are' : 'y is'} ready to be added as a source.`;
    }

    if (data.params.fileEnrichProfiles) {
      subTitle = `All set! ${total ?? 0} compan${total > 1 ? 'ies' : 'y'} will be matched against the existing companies in the collection.`;
      if (isSource) {
        subTitle = `All set! ${total ?? 0} compan${total > 1 ? 'ies' : 'y'} will be matched against the existing companies in the database.`;
      }
      if (data.params.fileOverwriteValues) {
        subTitle += ' Existing company info will be overwritten.';
      }
    } else if (data.params.fileOverwriteValues) {
      subTitle = `All set! ${total ?? 0} compan${total > 1 ? 'ies are' : 'y is'} ready to be added to the collection. Existing company info will be overwritten.`;
      if (isSource) {
        subTitle = `All set! ${total ?? 0} compan${total > 1 ? 'ies are' : 'y is'} ready to be added as a source. Existing company info will be overwritten.`;
      }
    }

    return <Box className="EntitiesFinalizeWizardContent-subtitle">
      <H6>{subTitle}</H6>
    </Box>
  };

  return <StyledEntitiesFinalizeWizardContent ref={ref} {...innerProps}>
    {renderSubtitle()}
    <Box className="EntitiesFinalizeWizardContent-table">
      <Table enableParentScroll={false}
             enableBottomToolbar={false}
             enableStickyHeader={false}
             enableSorting={false}
             enableEditing={false}
             enableColumnDragging={false}
             enableColumnActions={false}
             enableColumnOrdering={false}
             enableColumnResizing={false}
             enablePinning={false}
             listState={listState}
             columns={columnsMemo}
             data={entities}
             rowCount={entities?.length}
             debounce={false}
             state={{
               isLoading: false,
               showProgressBars: false
             }} />
    </Box>
  </StyledEntitiesFinalizeWizardContent>
});

EntitiesFinalizeWizardContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  wizard: PropTypes.object,
  step: PropTypes.object,
  isSource: PropTypes.bool,
  onComplete: PropTypes.func,
  onDirty: PropTypes.func,
  onError: PropTypes.func,
};

EntitiesFinalizeWizardContent.defaultProps = {
};

export default EntitiesFinalizeWizardContent;
