import {useAuthClientId} from 'services/auth/auth.utils';
import utils from 'helpers/utils';
import {useCallback, useMemo} from 'react';
import {useClientCustomFieldCache} from 'services/client/customField/customField.hooks';
import {
  useCollectionCustomFieldCache,
  useCollectionCustomFieldUpdate
} from 'services/collection/customField/customField.hooks';

export function useCollectionCustomFieldGroups (collectionId) {
  const clientId = useAuthClientId();
  const clientCustomFields = useClientCustomFieldCache({clientId}, {enabled: utils.isDefined(clientId)});
  const collectionCustomFields = useCollectionCustomFieldCache({clientId, collectionId}, {enabled: utils.isDefined(clientId) && utils.isDefined(collectionId)});

  return useMemo(() => {
    const customFields = ((clientCustomFields.data?.data ?? clientCustomFields.data) ?? [])
      .concat((collectionCustomFields.data?.data ?? collectionCustomFields.data) ?? []);

    const groups = customFields?.reduce((acc, f) => {
      if (f.groupName) {
        acc.push({
          label: utils.upperFirst(f.groupName),
          value: utils.upperFirst(f.groupName)
        });
      }
      return acc;
    }, []);
    return utils.uniqueArray(groups ?? [], null, true)
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [clientCustomFields.data, collectionCustomFields.data]);
}

export function useCollectionCustomFieldPatch () {
  const clientId = useAuthClientId();
  const updateField = useCollectionCustomFieldUpdate();

  return useCallback((collection, field, changes) => {
    changes = (Object.keys(changes).length > 1) ? utils.changedFormFields(field, changes) : changes;

    if (!utils.isEmpty(changes)) {
      return updateField.mutation.mutateAsync({
        clientId,
        collectionId: collection?.collectionId,
        fieldId: field.fieldId,
        ...changes
      });
    } else {
      return Promise.resolve();
    }
  }, [clientId, updateField.mutation]);
}
