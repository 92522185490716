import React, {useMemo, useRef} from 'react';
import StyledCollectionsPage from 'components/pages/Collection/CollectionsPage/CollectionsPage.styles';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent, useOptimistic} from 'helpers/hooks/utils';
import Table from 'components/organisms/Tables/Table/Table';
import FieldTableCellEdit from 'components/organisms/TableCellEdits/FieldTableCellEdit/FieldTableCellEdit';
import constants from 'helpers/constants';
import utils from 'helpers/utils';
import Star from '@mui/icons-material/Star';
import Edit from '@mui/icons-material/Edit';
import ContentCopy from '@mui/icons-material/ContentCopy';
import Delete from '@mui/icons-material/Delete';
import FolderSharp from '@mui/icons-material/FolderSharp';
import CheckboxTableCell from 'components/molecules/TableCells/CheckboxTableCell/CheckboxTableCell';
import Icon from 'components/atoms/Icons/Icon/Icon';
import {useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import TextTableCell from 'components/molecules/TableCells/TextTableCell/TextTableCell';
import DealflowBarChartTableCell
  from 'components/molecules/TableCells/DealflowBarChartTableCell/DealflowBarChartTableCell';
import TableHeaderIcon from 'components/molecules/TableHeaders/TableHeaderIcon/TableHeaderIcon';
import TableHeaderText from 'components/molecules/TableHeaders/TableHeaderText/TableHeaderText';
import ButtonGroupTableCell from 'components/molecules/TableCells/ButtonGroupTableCell/ButtonGroupTableCell';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';
import {useLinkNavigate} from 'helpers/hooks/links';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import CollectionVisibilityTableCellEdit
  from 'components/organisms/TableCellEdits/CollectionVisibilityTableCellEdit/CollectionVisibilityTableCellEdit';
import CollectionVisibilityTableCell
  from 'components/molecules/TableCells/CollectionVisibilityTableCell/CollectionVisibilityTableCell';
import CollectionsActionbar from 'components/organisms/Snackbars/CollectionsActionbar/CollectionsActionbar';
import FieldTableCell from 'components/molecules/TableCells/FieldTableCell/FieldTableCell';
import ConfirmDialog from 'components/organisms/Dialogs/ConfirmDialog/ConfirmDialog';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import {useDialogControl} from 'components/organisms/Providers/DialogProvider/DialogProvider';
import TableWrapper from 'components/templates/Wrappers/Headers/TableWrapper/TableWrapper';

const NameCell = React.forwardRef(({cell, row, column, table}, ref) => {
  const enableEditing = utils.isFunction(column.columnDef.enableEditing) ?
    column.columnDef.enableEditing(row) : Boolean(column.columnDef.enableEditing);
  const name = cell.column.columnDef.optimistic.get(cell, cell.getValue());

  const setEditingCellEvent = useEffectEvent(() => table.setEditingCell(cell));
  const action = useMemo(() => ({
    label: 'Edit',
    tooltip: 'Edit',
    auth: !enableEditing ? utils.createAuth({attribute: 'system.null'}) : null,
    icon: Edit,
    onClick: (e) => {
      setEditingCellEvent?.();
      e.preventDefault();
    }
  }), [enableEditing, setEditingCellEvent]);

  return <TextTableCell ref={ref}
                        title={name}
                        icon={<Icon icon={FolderSharp} color="primary"/>}
                        action={action}/>
});

const NameCellEdit = React.forwardRef(({column, cell, table, columnDef, fieldData, update}, ref) => {
  const name = cell.column.columnDef.optimistic.get(cell, cell.getValue());
  const field = {
    ...columnDef,
    name: column.id,
    label: column.columnDef.header,
    type: constants.formFieldTypes.text,
    validation: constants.formFieldValidationTypes.text,
    required: true,
    initial: name
  };

  const handleChange = (field, value, onSuccess, onError) => {
    onSuccess?.(); // optimistic
    cell.column.columnDef.optimistic.set(cell, value);
    update?.(cell.row.original, {name: value}).catch(() => {
      onError?.();
      cell.column.columnDef.optimistic.reset(cell);
    });
  }

  return <FieldTableCellEdit ref={ref}
                             cell={cell}
                             table={table}
                             fields={[field]}
                             fieldData={fieldData}
                             Anchor={<TextTableCell title={name}
                                                    icon={<Icon icon={FolderSharp} color="primary"/>} />}
                             onChange={handleChange}/>
});

const FavoriteCell = React.forwardRef(({cell, column, canUpdate, toggleFavorite}, ref) => {
  const [favorite, toggleFavoriteOptimistic] = useOptimistic(cell.getValue(), toggleFavorite);

  const ActionCheckboxProps = useMemo(() => ({
    showInactive: true,
    density: 'dense',
    action: {
      label: 'Favorite',
      tooltip: 'Favorite',
      auth: !canUpdate ? utils.createAuth({attribute: 'system.null'}) : null,
      icon: <Icon icon={Star} color="divider"/>,
      checkedIcon: <Icon icon={Star} color="amber.500"/>,
      active: Boolean(favorite),
      onClick: (e) => {
        toggleFavoriteOptimistic(!favorite, cell.row.original, !favorite).then();

        e.preventDefault();
      }
    }
  }), [favorite, canUpdate, toggleFavoriteOptimistic, cell.row.original]);

  return <CheckboxTableCell ref={ref}
                            className="favorite"
                            ActionCheckboxProps={ActionCheckboxProps}/>
});

const VisibilityCell = React.forwardRef(({cell, column, row, table}, ref) => {
  const enableEditing = utils.isFunction(column.columnDef.enableEditing) ?
    column.columnDef.enableEditing(row) : Boolean(column.columnDef.enableEditing);
  const visibility = cell.column.columnDef.optimistic.get(cell, cell.getValue());

  const handleClick = (e) => {
    table.setEditingCell(cell);
    e.preventDefault();
  }

  return <CollectionVisibilityTableCell ref={ref}
                                        visibility={visibility}
                                        disabled={!enableEditing}
                                        onClick={handleClick}/>
});

const VisibilityCellEdit = React.forwardRef(({cell, table, toggleVisibility}, ref) => {
  const visibility = cell.column.columnDef.optimistic.get(cell, cell.getValue());

  return <CollectionVisibilityTableCellEdit ref={ref}
                                            cell={cell}
                                            table={table}
                                            visibility={visibility}
                                            onChange={toggleVisibility}/>
});

const ActionsCell = React.forwardRef(({cell, clone, del, clearSelection, navigate, snackbar, dialogControl}, ref) => {
  const collection = cell.row.original;
  const actions = useMemo(() => ([
    {
      auth: utils.createAuth({attribute: 'collection.clone', meta: {collection}}),
      label: 'Copy collection',
      tooltip: 'Copy collection',
      icon: ContentCopy,
      onClick: (e) => {
        clone?.({collectionId: +collection.collectionId})
          .then((res) => {
            const collection = utils.camelcase(res.response.data.data);
            navigate({
              event: e,
              to: `./${collection.collectionId}/entities`
            });
          }).catch(() => {
            snackbar.show('Copying collection failed', null,
              {color: 'error', autoHideDuration: constants.delay.error});
          });
        e.preventDefault();
      }
    },
    {
      auth: utils.createAuth({attribute: 'collection.delete', meta: {collection}}),
      label: 'Remove collection',
      tooltip: 'Remove collection',
      icon: Delete,
      onClick: (e) => {
        const handleConfirm = () => {
          del?.({collectionId: +collection.collectionId})
            .then(() => {
              // clear selection on deleting items
              clearSelection?.();
            })
            .catch(() => {
              snackbar.show('Removing collection failed', null,
                {color: 'error', autoHideDuration: constants.delay.error});
            });
        }

        dialogControl.show(<ConfirmDialog question="Are you sure you want to remove this collection?"
                                          explanation="This will remove the collection including all collection related data"
                                          challenge={collection.name}
                                          challengeLabel="collection name"
                                          onConfirm={handleConfirm}
                                          size="medium"
                                          ConfirmButtonProps={{
                                            children: 'Remove collection',
                                            color: 'error'
                                          }} />, true);
        e.preventDefault();
      }
    }
  ]), [collection, clone, del, clearSelection, navigate, snackbar, dialogControl]);

  const ActionButtonGroupProps = useMemo(() => ({
    ActionButtonComponent: ActionIconButton,
    ActionButtonProps: {
      showInactive: true,
      variant: 'outlined',
      size: 'smaller',
      density: 'sparse',
      IconProps: {
        size: 'smaller'
      }
    },
    radius: 'round',
    variant: 'outlined',
    actions
  }), [actions]);

  return <ButtonGroupTableCell ref={ref}
                               ActionButtonGroupProps={ActionButtonGroupProps}/>
});

const FieldCell = React.forwardRef(({table, row, column, cell, columnDef, fieldData}, ref) => {
  const enableEditing = utils.isFunction(column.columnDef.enableEditing) ?
    column.columnDef.enableEditing(row) : Boolean(column.columnDef.enableEditing);
  const collection = cell.row.original;
  const field = utils.initializeFormFields([columnDef], collection)[0];
  field.initial = cell.column.columnDef.optimistic.get(cell, field.initial);

  const setEditingCellEvent = useEffectEvent(() => table.setEditingCell(cell));
  const action = useMemo(() => ({
    label: 'Edit',
    tooltip: 'Edit',
    auth: !enableEditing ? utils.createAuth({attribute: 'system.null'}) : null,
    icon: Edit,
    onClick: (e) => {
      setEditingCellEvent?.();
      e.preventDefault();
    }
  }), [enableEditing, setEditingCellEvent]);

  return <FieldTableCell ref={ref}
                         fields={[field]}
                         fieldData={fieldData}
                         action={action}/>
});

const FieldCellEdit = React.forwardRef(({cell, table, columnDef, fieldData, toggleLabel, update}, ref) => {
  const collection = cell.row.original;
  const field = utils.initializeFormFields([columnDef], collection)[0];
  field.initial = cell.column.columnDef.optimistic.get(cell, field.initial);

  const handleChange = (field, value, onSuccess, onError) => {
    let promises = [];
    if (field.name === 'labels') {
      const labels = cell.column.columnDef.optimistic.get(cell, field.initial);
      const add = (value ?? []).filter((l1) => !(labels ?? []).find((l2) => +l1.labelId === +l2.labelId || l1.label.toLowerCase() === l2.label.toLowerCase()));
      const remove = (labels ?? []).filter((l1) => !(value ?? []).find((l2) => +l1.labelId === +l2.labelId));

      add.forEach((l) => {
        promises.push(toggleLabel?.([collection], l, true));
      });
      remove.forEach((l) => {
        promises.push(toggleLabel?.([collection], l, false));
      });
    } else {
      promises.push(update?.(collection, {[field.name]: value}));
    }

    onSuccess?.(); // optimistic
    cell.column.columnDef.optimistic.set(cell, value);
    Promise.all(promises).catch(() => {
      onError?.();
      cell.column.columnDef.optimistic.reset(cell);
    });
  }

  return <FieldTableCellEdit ref={ref}
                             cell={cell}
                             table={table}
                             fields={[field]}
                             fieldData={fieldData}
                             onChange={handleChange}/>
});

const CollectionsPage = (props) => {
  const innerProps = useComponentProps(props, 'CollectionsPage');

  const tableProvider = useTable();
  const list = tableProvider.list;
  const fieldData = tableProvider.fieldData;
  const columnDefinitions = tableProvider.columnDefinitions;

  // effects
  const tableRef = useRef(null);

  // mutations
  const authorize = useAuthorize();
  const snackbar = useSnackbar();
  const dialogControl = useDialogControl();

  const navigate = useLinkNavigate();

  const cloneEvent = useEffectEvent(tableProvider.updaters?.cloneData);
  const updateEvent = useEffectEvent(tableProvider.updaters?.updateData);
  const deleteEvent = useEffectEvent(tableProvider.updaters?.deleteData);
  const toggleLabelEvent = useEffectEvent(tableProvider.updaters?.toggleLabel);
  const toggleFavoriteEvent = useEffectEvent(tableProvider.updaters?.toggleFavorite);
  const toggleVisibilityEvent = useEffectEvent(tableProvider.updaters?.toggleVisibility);
  const clearSelectionEvent = useEffectEvent(tableProvider.listSelection?.clearSelection);
  const columns = useMemo(() => {
    const columns = [];
    if (columnDefinitions) {
      columnDefinitions
        .sort((a, b) => a.position - b.position)
        .forEach((columnDef) => {
          const canEditColumn = (collection) => {
            return !(columnDef.readOnly ?? false) &&
              authorize(columnDef.auth?.update ? {...columnDef.auth?.update, meta: {...columnDef.auth?.update?.meta, collection}} : {}) &&
              authorize({attribute: 'collection.update', meta: {collection}});
          }

          if (columnDef.name === 'name') {
            columns.push({
              accessorKey: columnDef.name,
              id: columnDef.id,
              header: 'Collection name',
              size: 200,
              minSize: 200,
              enableSorting: columnDef.sortable !== false,
              enableEditing: (row) => {
                return canEditColumn(row.original);
              },
              Cell: (props) => <NameCell {...props} />,
              Edit: (props) => <NameCellEdit {...props} columnDef={columnDef} fieldData={fieldData} update={updateEvent} />
            })
          } else if (columnDef.name === 'favorite') {
            columns.push({
              accessorKey: columnDef.name,
              id: columnDef.id,
              header: 'Favorite',
              size: 120,
              minSize: 120,
              muiTableHeadCellProps: {
                align: 'center',
              },
              muiTableBodyCellProps: {
                align: 'center',
              },
              enableEditing: false,
              enableSorting: columnDef.sortable !== false,
              Header: () => {
                return <TableHeaderIcon tooltip="Favorite" className="favorite"
                                        icon={<Icon icon={Star} color="divider"/>}/>
              },
              Cell: (props) => {
                const enableEditing = canEditColumn(props.row.original);

                return <FavoriteCell {...props} canUpdate={enableEditing} toggleFavorite={toggleFavoriteEvent} />;
              }
            })
          } else if (columnDef.name === 'entities') {
            columns.push({
              accessorKey: columnDef.name,
              id: columnDef.id,
              header: columnDef.header,
              minSize: 140,
              size: 140,
              muiTableHeadCellProps: {
                align: 'center',
              },
              muiTableBodyCellProps: {
                align: 'center',
              },
              enableEditing: false,
              enableSorting: columnDef.sortable !== false,
              Cell: (props) => <FieldCell {...props} columnDef={columnDef} fieldData={fieldData} />
            });
          } else if (columnDef.name === 'visibility') {
            columns.push({
              accessorKey: columnDef.name,
              id: columnDef.id,
              header: 'Privacy',
              minSize: 130,
              size: 130,
              enableSorting: columnDef.sortable !== false,
              enableEditing: (row) => {
                return canEditColumn(row.original);
              },
              Cell: (props) => <VisibilityCell {...props} />,
              Edit: (props) => <VisibilityCellEdit {...props} toggleVisibility={toggleVisibilityEvent} />
            });
          } else if (columnDef.name === 'dealflow') {
            columns.push({
              accessorKey: columnDef.name,
              id: columnDef.id,
              header: 'Dealflow',
              enableEditing: false,
              enableSorting: false,
              Cell: ({cell}) => {
                const total = +cell.row.original.entities;
                const dealflow = cell.getValue();

                const handleClick = (e, release, data) => {
                  const status = data.tooltipPayload?.[0]?.dataKey;
                  if (status) {
                    navigate({
                      event: e,
                      to: `/collections/${cell.row.original.collectionId}/entities?custom=dealflow:${status}_-1`,
                      keepSearchParams: false
                    });

                    e.preventDefault();
                  }
                };

                return <DealflowBarChartTableCell total={total}
                                                  onClick={handleClick}
                                                  dealflow={dealflow ?? []}/>
              }
            });
          } else if (columnDef.name === 'actions') {
            columns.push({
              accessorKey: columnDef.name,
              id: columnDef.id,
              header: 'Actions',
              enableEditing: false,
              enableSorting: false,
              size: 100,
              minSize: 100,
              muiTableBodyCellProps: {
                align: 'right',
              },
              Header: () => <TableHeaderText header=" "/>,
              Cell: (props) => <ActionsCell {...props}
                                            clone={cloneEvent} del={deleteEvent}
                                            clearSelection={clearSelectionEvent} navigate={navigate}
                                            snackbar={snackbar} dialogControl={dialogControl} />
            });
          } else {
            columns.push({
              accessorKey: utils.camelcase(columnDef.name),
              id: columnDef.id,
              header: columnDef.header,
              enableSorting: columnDef.sortable !== false,
              enableEditing: (row) => {
                return canEditColumn(row.original);
              },
              Cell: (props) => <FieldCell {...props} columnDef={columnDef} fieldData={fieldData} />,
              Edit: (props) => <FieldCellEdit {...props}
                                              columnDef={columnDef}
                                              fieldData={fieldData}
                                              toggleLabel={toggleLabelEvent}
                                              update={updateEvent} />

            })
          }
        });
    }

    return columns;
  }, [authorize, columnDefinitions, fieldData, dialogControl, snackbar, cloneEvent, navigate,
    updateEvent, deleteEvent, toggleLabelEvent, toggleFavoriteEvent, toggleVisibilityEvent, clearSelectionEvent]);

  const handleCanRowClick = (collection) => {
    return authorize({attribute: 'collection.entity.list', meta: {collection}});
  }

  const handleRowClick = (e, collection) => {
    navigate({
      event: e,
      to: `./${collection.collectionId}/entities`
    });
  }

  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledCollectionsPage as={TableWrapper} {...innerProps}
                                borders={{left: '3sp', right: '3sp'}}>
    <Table className="CollectionsPage-table"
           dataKey={tableProvider.dataKey}
           enableSorting={true}
           enableEditing={true}
           enableParentScroll={true}
           enableBottomToolbar={false}
           enableStickyHeader={true}
           enableColumnDragging={true}
           enableColumnOrdering={true}
           enableColumnResizing={true}
           enablePinning={true}
           enableScrollReset={true}
           listState={tableProvider.listState}
           columnState={tableProvider.columnState}
           listSelection={tableProvider.listSelection}
           onFetchMore={list.query.fetchNextPage}
           onRefetch={list.query.refetch}
           onCanRowClick={handleCanRowClick}
           onRowClick={handleRowClick}
           ref={tableRef}
           columns={columns}
           data={list.data}
           rowCount={list.meta?.resultsCount}
           state={{
             isLoading: list.status.isLoading,
             showProgressBars: list.status.isLoadingNext
           }}
           ActionbarComponent={<CollectionsActionbar tableProvider={tableProvider} />} />
  </StyledCollectionsPage>
};

CollectionsPage.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ])
};

CollectionsPage.defaultProps = {
};

export default CollectionsPage;
