import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledFieldTableCellEdit from 'components/organisms/TableCellEdits/FieldTableCellEdit/FieldTableCellEdit.styles';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import constants from 'helpers/constants';
import FieldTableCell from 'components/molecules/TableCells/FieldTableCell/FieldTableCell';
import {withMemo} from 'helpers/wrapper';

const FieldTableCellEdit = withMemo(React.forwardRef((props, ref) => {
  const {
    fields,
    fieldData,
    onChange,
    onDirty,
    ...innerProps
  } = useComponentProps(props, 'FieldTableCellEdit');

  const [busy, setBusy] = useState(false);

  const snackbar = useSnackbar();

  const onDirtyEvent = useEffectEvent(onDirty);

  useEffect(() => {
    onDirtyEvent?.(busy);
  }, [busy, onDirtyEvent]);

  const handleChange = (field, value, onSuccess, onError) => {
    const handleError = (err) => {
      innerProps.table.setEditingCell((current) => {
        if (current?.id !== innerProps.cell?.id) {
          snackbar.show(err ?? 'Saving failed', null,
            {color: 'error', autoHideDuration: constants.delay.error});
        }

        return current;
      });
      onError?.(err);
    }
    onChange?.(field, value, onSuccess, handleError);
  };

  const fieldsMemo = useMemo(() => {
    return fields.map((field) => {
      field = {
        ...field,
        context: {
          ...field.context,
          name: innerProps.cell.row.original?.name,
          legalName: innerProps.cell.row.original?.legalName,
          country: innerProps.cell.row.original?.location?.country
        },
        FormFieldProps: utils.mergeObjects(field.FormFieldProps, {
          size: 'smaller',
          variant: !(fields.length > 1) ? 'inlineLabel' : 'staticLabel',
          hiddenLabel: !(fields.length > 1),
          fullWidth: true,
          debounce: true
        }, true)
      };

      return field;
    });
  }, [fields, innerProps.cell.row.original]);

  const Anchor = innerProps.Anchor ?? <FieldTableCell fields={fieldsMemo}
                                                      fieldData={fieldData}
                                                      active={true}/>;

  const minWidth = fieldsMemo.reduce((m, f) => {
    return utils.isDefined(f.FormFieldProps?.minWidth) ? Math.max(m ?? 0, f.FormFieldProps?.minWidth) : m
  }, null);

  const maxWidth = fieldsMemo.reduce((m, f) => {
    return utils.isDefined(f.FormFieldProps?.maxWidth) ? Math.max(m ?? 0, f.FormFieldProps?.maxWidth) : m
  }, null);

  return <StyledFieldTableCellEdit ref={ref} {...innerProps}
                                   $minWidth={minWidth}
                                   $maxWidth={maxWidth}
                                   autoClose={!busy}
                                   autoFocus={false}
                                   TableCellPopperProps={{
                                     density: 'densest'
                                   }}
                                   Anchor={Anchor}>
    <InlineForm onChange={handleChange}
                autoTouch={true}
                fields={fieldsMemo}
                fieldData={fieldData}
                onBusy={setBusy}/>
  </StyledFieldTableCellEdit>
}));

FieldTableCellEdit.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  icon: PropTypes.any,
  logo: PropTypes.any,
  fields: PropTypes.array,
  fieldData: PropTypes.object,
  onChange: PropTypes.func
};

FieldTableCellEdit.defaultProps = {
  children: 'FieldTableCellEdit text'
};

export default FieldTableCellEdit;
