import PropTypes from 'prop-types';
import styled from 'styled-components';
import ComponentStyles from 'styles/mixins/Component.styles';
import Box from 'components/atoms/Layout/Box/Box';

const StyledRelevancyDistributionPieChart = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.layout('2sp')};
  height: 100%;
  
  .RelevancyDistributionPieChart {
    &-chart {
      height: 100%;
      overflow: hidden;
      gap: ${props => props.theme.layout('2sp')};
    }
    
    &-chart-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 100%;
      max-height: 100%;
      aspect-ratio: 1;
    }

    &-center {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;

      .totalCount {
        font-size: ${props => props.theme.fontSize(40)};
        line-height: ${props => props.theme.lineHeight('1.125f')};
        font-weight: 700;
      }

      .H6 {
        text-align: center;
      }
    }
    
    &-legend {
      flex-grow: 1;
      align-items: center;
    }
  }

  // variants
  &.RelevancyDistributionPieChart-half {
    .RelevancyDistributionPieChart {
      &-center {
        height: 100%;
        justify-content: end;

        .Icon {
          font-size: ${props => props.theme.fontSize(24)};
        }

        .totalCount {
          margin-top: ${props => props.theme.layout(4)};
          margin-bottom: ${props => props.theme.layout(-3)};
          font-size: ${props => props.theme.fontSize(20)};
          line-height: ${props => props.theme.lineHeight('1f')};
          font-weight: 400;
        }

        .title {
          display: none;
        }
      }
    }
  }

  &.RelevancyDistributionPieChart-orientation-horizontal {
    width: 100%;
    flex-direction: row;
    gap: ${props => props.theme.layout('6sp')};
    .RelevancyDistributionPieChart {
      &-chart {
        display: flex;
        align-items: center;
        width: 100%;
      }

      &-legend {
        min-width: 46%;
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledRelevancyDistributionPieChart.propTypes = {
  theme: PropTypes.object
};

StyledRelevancyDistributionPieChart.defaultProps = {}

export default StyledRelevancyDistributionPieChart;
