import styled from 'styled-components';
import PropTypes from 'prop-types';
import ComponentStyles from 'styles/mixins/Component.styles';
import Card from 'components/atoms/Cards/Card/Card';

const StyledOnboarding = styled(Card)`
  width: ${props => props.theme.layout(352)};
  min-width: ${props => props.theme.layout(352)};
  max-width: ${props => props.theme.layout(352)};
  max-height: clamp(0px, ${props => props.theme.layout(644)}, 80vh);
  overflow: hidden;

  .Onboarding {
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: ${props => props.theme.spacing(1.25)} ${props => props.theme.spacing(1.25)} ${props => props.theme.spacing(1.25)} ${props => props.theme.spacing(3)};
      color: ${props => props.theme.property('palette.primary.contrastText')};
      background-color: ${props => props.theme.property('palette.primary.main')};
    }
    
    &-stepper {
      color: ${props => props.theme.property('palette.secondary.contrastText')};
      background-color: ${props => props.theme.property('palette.secondary.main')};

      .Step {
        &:not(:last-child) {
          border-bottom: 1px solid ${props => props.theme.property('palette.white.selected')};
        }

        .StepButton {
          box-sizing: border-box;
          padding: ${props => props.theme.spacing(2)};
          margin: 0;

          .MuiStepIcon-root {
            color: ${props => props.theme.property('palette.success.main')};

            &:not(.Mui-completed) {
              color: transparent;
              stroke: ${props => props.theme.property('palette.blueGrey.500')};

              circle {
                stroke: rgba(106, 118, 138, 1);
                r: 11;
              }
            }
          }

          .MuiStepLabel-label {
            color: ${props => props.theme.property('palette.secondary.contrastText')};
          }

          .MuiTouchRipple-root {
            > * {
              background-color: ${props => props.theme.property('palette.blueGrey.500')};
            }
          }
        }

        .StepContent {
          color: ${props => props.theme.property('palette.blueGrey.500')};

          .MuiCollapse-wrapperInner.MuiCollapse-vertical {
            display: flex;
            flex-direction: column;
            gap: ${props => props.theme.layout('1.25sp')};

            padding: 0 ${props => props.theme.spacing(1.5)};
            padding-bottom: ${props => props.theme.spacing(3)};
          }
        }
      }
    }
  }
  
  ${ComponentStyles}
`;

StyledOnboarding.propTypes = {
  theme: PropTypes.object
};

StyledOnboarding.defaultProps = {
}

export default StyledOnboarding;
