import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import StyledFieldTableCell from 'components/molecules/TableCells/FieldTableCell/FieldTableCell.styles';
import utils from 'helpers/utils';
import FormField from 'components/organisms/Fields/FormField/FormField';
import Icon from 'components/atoms/Icons/Icon/Icon';
import {useActionItem, useActionItemAction} from 'helpers/hooks/action';
import {withMemo} from 'helpers/wrapper';
import Tooltip from 'components/atoms/Tooltips/Tooltip/Tooltip';

const FieldTableCell = withMemo(React.forwardRef((props, ref) => {
  const {
    fields,
    fieldData,
    action,
    active,
    onClick,
    ...innerProps
  } = useComponentProps(props, 'FieldTableCell', {
    static: ['canUpdate']
  });

  const itm = useActionItem(action);
  const handleClick = useActionItemAction(itm?.onClick, onClick);

  const formFields = useMemo(() => {
    return fields.map((field) => {
      const formField = utils.fields2FormFields([field], fieldData)[0];
      const initialValues = utils.fields2InitialValues([formField]);
      return {
        ...formField,
        value: initialValues[formField.name],
        readOnly: true,
        FormFieldProps: utils.mergeObjects(formField.FormFieldProps, {
          size: 'smaller',
          fullWidth: true,
          minWidth: null,
          maxWidth: null,
          variant: 'inlineLabel',
          hiddenLabel: true,
          hiddenHelperText: true,
          hiddenPlaceholder: true,
          showLinkIcon: true
        }, true)
      };
    });
  }, [fields, fieldData]);

  innerProps.className = utils.flattenClassName(innerProps.className, {
    canUpdate: Boolean(itm)
  });

  const feedback = formFields.find((f) => f.feedback)?.feedback;

  return <StyledFieldTableCell ref={ref} {...innerProps} onClick={handleClick}>
    {feedback ? <Tooltip title={feedback.tooltip}>
      <Icon className="FieldTableCell-feedback"
            icon={formFields[0].feedback.icon}
            color={formFields[0].feedback.color ?? 'error'}
            size="smaller"/>
    </Tooltip> : null}
    {formFields
      .filter((formField) => !utils.isEmpty(formField?.value))
      .map((formField, idx) => {
        return <FormField key={idx}
                          className="FieldTableCell-field"
                          field={formField}
                          value={formField.value}
                          {...formField.FormFieldProps}/>
      })}
    {itm ? <Icon className="FieldTableCell-edit" icon={itm?.icon} size="tiny"/> : null}
  </StyledFieldTableCell>
}));

FieldTableCell.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  fields: PropTypes.array,
  fieldData: PropTypes.object,
  active: PropTypes.bool,
  action: PropTypes.object,
  onClick: PropTypes.func
};

FieldTableCell.defaultProps = {
};

export default FieldTableCell;


