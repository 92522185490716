import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectItem} from 'helpers/hooks/utils';
import utils from 'helpers/utils';
import StyledEntitiesTableBar from 'components/organisms/Bars/EntitiesTableBar/EntitiesTableBar.styles';
import {useTable} from 'components/organisms/Providers/TableProvider/TableProvider';
import {useDialogControl} from 'components/organisms/Providers/DialogProvider/DialogProvider';
import EntitiesChatDialog from 'components/organisms/Dialogs/EntitiesChatDialog/EntitiesChatDialog';

const EntitiesTableBar = React.forwardRef((props, ref) => {
  const {
    chatAction,
    filterAction,
    columnAction,
    ...innerProps
  } = useComponentProps(props, 'EntitiesTableBar');

  const tableProvider = useTable();

  const dialogControl = useDialogControl();
  const listStateMemo = useEffectItem(tableProvider.appliedListState());

  const searchField = useMemo(() => ({
    placeholder: 'Search for a company'
  }), []);

  const chatActionMemo = useMemo(() => ({
    auth: utils.createAuth({ attribute: 'entity.chat'}),
    onClick: () => {
      dialogControl.show(<EntitiesChatDialog listState={listStateMemo}/>, true);
    },
    ...chatAction
  }), [chatAction, listStateMemo, dialogControl]);

  const filterActionMemo = useMemo(() => ({
    auth: utils.createAuth({ attribute: 'entity.filters'}),
    ...filterAction
  }), [filterAction]);

  const columnActionMemo = useMemo(() => ({
    auth: utils.createAuth({ attribute: 'entity.columns'}),
    ...columnAction
  }), [columnAction]);

  const entitiesCount = utils.formatNumber(tableProvider.list?.meta?.resultsCount ?? 0);

  innerProps.title = innerProps.title ?? `${entitiesCount} compan${(tableProvider.list?.meta?.resultsCount ?? 0) === 1 ? 'y' : 'ies'}`;
  innerProps.className = utils.flattenClassName(innerProps.className);

  return <StyledEntitiesTableBar ref={ref} {...innerProps}
                                 searchField={searchField}
                                 chatAction={chatActionMemo}
                                 filterAction={filterActionMemo}
                                 columnAction={columnActionMemo}/>
});

EntitiesTableBar.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  chatAction: PropTypes.object,
  filterAction: PropTypes.object,
  columnAction: PropTypes.object
};

EntitiesTableBar.defaultProps = {
};

export default EntitiesTableBar;
