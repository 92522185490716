import React, {useImperativeHandle, useRef} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps} from 'helpers/hooks/utils';
import CollectionSectionPanelHeader
  from 'components/organisms/SectionPanels/CollectionSectionPanelHeader/CollectionSectionPanelHeader';
import CollectionProfile from 'components/organisms/Profiles/CollectionProfile/CollectionProfile';
import useMediaQuery from '@mui/material/useMediaQuery';
import StyledCollectionFieldsSectionPanelContent
  from 'components/organisms/SectionPanels/CollectionFieldsSectionPanelContent/CollectionFieldsSectionPanelContent.styles';

const info = 'Add and manage fields for your collection company profiles';

const CollectionFieldsSectionPanelContent = React.forwardRef((props, ref) => {
  const {
    section,
    onCanUpdate,
    ...innerProps
  } = useComponentProps(props, 'CollectionFieldsSectionPanelContent');

  const innerRef = useRef(null);

  useImperativeHandle(ref, () => innerRef.current);

  const handleShowCard = (card) => {
    return card.name.startsWith('customField');
  }

  const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));

  return <StyledCollectionFieldsSectionPanelContent ref={innerRef} {...innerProps}>
    <CollectionSectionPanelHeader title={section.title} info={info}/>
    <CollectionProfile columns={mdUp ? 2 : 1}
                       variant="grid"
                       onShowCard={handleShowCard}
                       onCanUpdate={onCanUpdate} />
  </StyledCollectionFieldsSectionPanelContent>

});

CollectionFieldsSectionPanelContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  section: PropTypes.object,
  onCanUpdate: PropTypes.func
};

CollectionFieldsSectionPanelContent.defaultProps = {};

export default CollectionFieldsSectionPanelContent;
