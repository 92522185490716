import utils from 'helpers/utils';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useAuthTeamId, useAuthUserId} from 'services/auth/auth.utils';
import {useClientCustomFieldCache} from 'services/client/customField/customField.hooks';
import {useCollectionGet, useCollectionList} from 'services/collection/collection.hooks';
import constants from 'helpers/constants';
import {useTagList} from 'services/tag/tag.hooks';
import {
  useEntityByName,
  useEntityByNameES,
  useEntityList,
  useEntitySearch, useEntitySearchCocNumber,
  useEntitySearchGoogle,
  useEntityUpdate
} from 'services/entity/entity.hooks';
import {useMergeFieldData, useMergeFilterGroupDefinitions} from 'helpers/hooks/utils';
import {useSourceList} from 'services/source/source.hooks';
import {useClientCallbacks} from 'services/client/client.utils';
import {useAuthClient, useAuthorize} from 'components/organisms/Providers/AuthProvider/AuthProvider';
import {Warning} from '@mui/icons-material';
import {useEntityPatentCpcSearch} from 'services/entity/patent/cpc/cpc.hooks';
import {useCollectionCustomFieldCache} from 'services/collection/customField/customField.hooks';

export function processEntity (entity) {
  const tagGroups = (entity.tagsGroups || [])
    .map((tg) => utils.camelcaseEx(tg));
  const tagsObj = utils.camelcaseEx(entity.tagsObj);

  const getTags = (system) => {
    return (tagsObj || [])
      .filter((t) => (system && +t.ownerClientId === 0) || (!system && +t.ownerClientId > 0))
      .sort((a, b) => {
        return a.value.localeCompare(b.value) ? a.value.localeCompare(b.value) : +a.tagId - +b.tagId;
      });
  };

  entity = utils.camelcase(entity);

  return {
    ...entity,
    type: entity.type ?? entity.evalType,
    tags: getTags(false),
    systemTags: getTags(true),
    ...entity.custom?.reduce((o, cf) => {
      o[utils.camelcase(cf.name)] = cf.value
      return o;
    }, {}),
    tagGroups: tagGroups.map((tg) => ({
      groupId: tg.groupId,
      tags: tg.selectedTags
    })),
    collections: utils.camelcaseEx(entity.collections ?? []),
    sources: utils.camelcaseEx(entity.sources ?? []),
    feeds: utils.camelcaseEx(entity.feeds ?? []),
    clientQuestions: utils.camelcaseEx(entity.clientQuestions ?? []),
    collectionQuestions: utils.camelcaseEx(entity.collectionQuestions ?? []),
    ...(entity.links?.reduce((o, l) => {
      if (l.value?.length > 0) {
        o[l.name] = l.value;
      }
      return o;
    }, {})),
    fileUploads: utils.camelcaseEx(entity.fileUploads ?? [])
      .map((f) => ({...f, uploadId: f.fileUploadId}))
  }
}

export function processEntityPoints (entity, tagGroup, client) {
  const questions = client ? entity.clientQuestions : entity.collectionQuestions;
  return questions
    .filter((q) => q.questionType === constants.collection.questionTypes.advanced && +q.tagGroup === +tagGroup.groupId)
    .reduce((t, q) => t + +q.points, 0);
}

export function processEntityFeedback (entity, field, value) {
  let suggestion, explanation, tooltip, isLink;

  if (field.link) {
    const enrichmentStats = entity?.enrichmentStats?.enrichment;
    if (enrichmentStats?.[`${field.link.value}Feedback`]) {
      isLink = true;
      tooltip = 'Verify link';
      if (field.link.value === 'website' && !enrichmentStats.websiteConfidence && enrichmentStats.linkedinWebsite &&
        utils.getHostname(entity.website) !== utils.getHostname(enrichmentStats.linkedinWebsite)) {
        if (utils.getHostname(value) !== utils.getHostname(enrichmentStats.linkedinWebsite) &&
          utils.getHostname(entity.website) === utils.getHostname(value)) {
          suggestion = enrichmentStats.linkedinWebsite;
        }
      } else if (!enrichmentStats[`${field.link.value}Confidence`]) {
        if (utils.isEmpty(entity[field.link.value])) {
          if (utils.isEmpty(value)) {
            explanation = 'Please enter the correct link for this company';
          }
        } else {
          if ((field.link.value !== 'linkedin' && utils.getHostname(entity[field.link.value]) === utils.getHostname(value)) ||
            (field.link.value === 'linkedin' && utils.cleanLinkedInHandle(entity[field.link.value]) === utils.cleanLinkedInHandle(value))) {
            explanation = 'We could not match the link to this company';
          }
        }
      }
    }
  } else if (utils.camelcase(field.name) === 'cocNumber') {
    const enrichmentStats = entity?.enrichmentStats?.companyInfo;
    if (enrichmentStats?.['cocNumberFeedback']) {
      isLink = false;
      tooltip = 'Verify CoC number';
      if (!enrichmentStats['cocNumberConfidence']) {
        if (utils.isEmpty(entity.cocNumber)) {
          if (utils.isEmpty(value)) {
            explanation = 'Please enter the correct CoC number for this company';
          }
        } else {
          if (entity.cocNumber === value) {
            explanation = 'We could not match the CoC number to this company';
          }
        }
      }
    }
  }

  if (suggestion || explanation) {
    return {
      icon: Warning,
      isLink: isLink,
      tooltip: tooltip,
      suggestion: suggestion,
      explanation: explanation
    }
  }
}

export function useEntityCallbacks () {
  const clientCallbacks = useClientCallbacks();

  const [nameListSearch, setNameListSearch] = useState({});
  const nameListEnabled = nameListSearch.search?.length > 0 && Boolean(nameListSearch.callback);
  const nameList = useEntityByName({
    name: nameListSearch.search,
    filter: utils.addFilter((utils.toArray(nameListSearch.ids, true).length > 0) ? [{
      id: 'entityId',
      value: utils.toArray(nameListSearch.ids, true)
        .filter((v) => utils.isInt(v))
        .map((v) => v.toString().startsWith('-') ? utils.toInt(v.toString().slice(1)) : utils.toInt(v))[0]
    }] : null, nameListSearch.filter),
    minimal: true,
    pageSize: 15
  }, {
    ...constants.queryOptions.runOnceNotStale,
    enabled: nameListEnabled
  });

  const nameListError = !nameList.status.isBusy && +nameList.status?.error?.response?.status >= 400;
  const nameListESEnabled = nameListSearch.search?.length > 0 && Boolean(nameListSearch.callback && nameListError);
  const nameListES = useEntityByNameES({
    name: nameListSearch.search,
    filter: utils.addFilter((utils.toArray(nameListSearch.ids, true).length > 0) ? [{
      id: 'entityId',
      value: utils.toArray(nameListSearch.ids, true)
        .filter((v) => utils.isInt(v))
        .map((v) => v.toString().startsWith('-') ? utils.toInt(v.toString().slice(1)) : utils.toInt(v))[0]
    }] : null, nameListSearch.filter),
    minimal: true,
    pageSize: 15
  }, {
    ...constants.queryOptions.runOnceNotStale,
    enabled: nameListESEnabled
  });

  const [tagListSearch, setTagListSearch] = useState({});
  const tagListEnabled = Boolean(tagListSearch.callback);
  const tagList = useTagList({
    search: tagListSearch.search,
    filter: utils.addFilter((utils.toArray(tagListSearch.ids, true).length > 0) ? [{
      id: 'tagIds',
      value: utils.toArray(tagListSearch.ids, true)
        .filter((v) => utils.isInt(v))
        .map((v) => utils.toInt(v))
    }] : null, tagListSearch.filter),
    pageSize: 15,
    minimal: true
  }, {
    ...constants.queryOptions.runOnceNotStale,
    enabled: tagListEnabled
  });

  const [cpcListSearch, setCpcListSearch] = useState({});
  const cpcListEnabled = Boolean(cpcListSearch.callback);
  const cpcList = useEntityPatentCpcSearch({
    search: cpcListSearch.search || 'A',
    filter: utils.addFilter((utils.toArray(cpcListSearch.ids, true).length > 0) ? [{
      id: 'cpcIds',
      value: utils.toArray(cpcListSearch.ids, true)
        .filter((v) => utils.isInt(v))
        .map((v) => utils.toInt(v))
    }] : null, cpcListSearch.filter),
    ipc: false,
    pageSize: 15
  }, {
    ...constants.queryOptions.runOnceNotStale,
    enabled: cpcListEnabled
  });

  const [ipcListSearch, setIpcListSearch] = useState({});
  const ipcListEnabled = Boolean(ipcListSearch.callback);
  const ipcList = useEntityPatentCpcSearch({
    search: ipcListSearch.search || 'A',
    filter: utils.addFilter((utils.toArray(ipcListSearch.ids, true).length > 0) ? [{
      id: 'cpcIds',
      value: utils.toArray(ipcListSearch.ids, true)
        .filter((v) => utils.isInt(v))
        .map((v) => utils.toInt(v))
    }] : null, ipcListSearch.filter),
    ipc: true,
    pageSize: 15
  }, {
    ...constants.queryOptions.runOnceNotStale,
    enabled: ipcListEnabled
  });

  const [collectionListSearch, setCollectionListSearch] = useState({});
  const collectionListEnabled = Boolean(collectionListSearch.callback);
  const collectionList = useCollectionList({
    search: collectionListSearch.search,
    filter: utils.addFilter((utils.toArray(collectionListSearch.ids, true).length > 0) ? [{
      id: 'collectionIds',
      value: utils.toArray(collectionListSearch.ids, true)
        .filter((v) => utils.isInt(v))
        .map((v) => utils.toInt(v))
    }] : null, collectionListSearch.filter),
    lookupOnly: true,
    allPages: true
  }, {
    ...constants.queryOptions.runOnceNotStale,
    enabled: collectionListEnabled
  });

  const [sourceListSearch, setSourceListSearch] = useState({});
  const sourceListEnabled = Boolean(sourceListSearch.callback);
  const sourceList = useSourceList({
    search: sourceListSearch.search,
    filter: utils.addFilter((utils.toArray(sourceListSearch.ids, true).length > 0) ? [{
      id: 'sourceIds',
      value: utils.toArray(sourceListSearch.ids, true)
        .filter((v) => utils.isInt(v))
        .map((v) => utils.toInt(v))
    }] : null, sourceListSearch.filter),
    pageSize: 100
  }, {
    ...constants.queryOptions.runOnceNotStale,
    enabled: sourceListEnabled
  });

  const [linkSuggestionSearch, setLinkSuggestionSearch] = useState({});
  const linkSuggestionEnabled = linkSuggestionSearch.search?.length > 0 && Object.keys(linkSuggestionSearch.links ?? {}).length > 0;
  const linkSuggestionList = useEntitySearchGoogle({
    query: linkSuggestionSearch.search,
    filter: linkSuggestionSearch.filter
  }, {
    ...constants.queryOptions.runOnce,
    enabled: linkSuggestionEnabled
  });
  const linkSuggestions = useLinkSuggestions(linkSuggestionList.data?.data, linkSuggestionSearch.name);

  const [cocNumberSuggestionSearch, setCocNumberSuggestionSearch] = useState({});
  const cocNumberSuggestionEnabled = cocNumberSuggestionSearch.name?.length > 0;
  const cocNumberSuggestionList = useEntitySearchCocNumber({
    name: cocNumberSuggestionSearch.name,
    country: cocNumberSuggestionSearch.country
  }, {
    ...constants.queryOptions.runOnce,
    enabled: cocNumberSuggestionEnabled
  });

  useEffect(() => {
    if (!(nameListEnabled || nameListESEnabled) ||
        (nameListEnabled && (nameList.data || (nameList.status.hasError && nameListES.status.hasError))) ||
        (nameListESEnabled && (nameListES.data || (nameList.status.hasError && nameListES.status.hasError)))) {
      const entities = (nameList.data ?? []).concat(nameListES.data ?? []);
      nameListSearch.callback?.(entities.map((e) => ({
        label: e.name,
        value: +e.entityId,
        entity: e
      })));
      if (nameListEnabled || nameListESEnabled) {
        setNameListSearch({});
      }
    }
  }, [nameListSearch, nameListEnabled, nameListESEnabled, nameList.data, nameListES.data,
    nameList.status.hasError, nameListES.status.hasError]);

  useEffect(() => {
    if (!tagListEnabled || tagList.data || tagList.status.hasError) {
      tagListSearch.callback?.((tagList.data ?? []).map((t) => ({tag: t, label: t.value?.toLowerCase(), value: +t.tagId})));
      if (tagListEnabled) {
        setTagListSearch({});
      }
    }
  }, [tagListSearch, tagListEnabled, tagList.data, tagList.status.hasError]);

  useEffect(() => {
    if (!cpcListEnabled || cpcList.data || cpcList.status.hasError) {
      cpcListSearch.callback?.(utils.uniqueArray((cpcList.data ?? []).map((c) => {
        let value = c.symbol;
        const level = c.titles.reduce((a, c) => c.level > a ? c.level : a, 0);

        if (level < 8) { // for high classes include wildcards
          if (c.symbol.includes('/')) {
            value = c.symbol.split('/')[0] + '/*';
          } else {
            value = c.symbol + '*';
          }
        }

        return {cpc: c, label: c.symbol, value};
      }), 'value'));

      if (cpcListEnabled) {
        setCpcListSearch({});
      }
    }
  }, [cpcListSearch, cpcListEnabled, cpcList.data, cpcList.status.hasError]);

  useEffect(() => {
    if (!ipcListEnabled || ipcList.data || ipcList.status.hasError) {
      ipcListSearch.callback?.(utils.uniqueArray((ipcList.data ?? []).map((i) => {
        let value = i.symbol;
        const level = i.titles.reduce((a, c) => c.level > a ? c.level : a, 0);

        if (level < 8) { // for high classes include wildcards
          if (i.symbol.includes('/')) {
            value = i.symbol.split('/')[0] + '/*';
          } else {
            value = i.symbol + '*';
          }
        }

        return {ipc: i, label: i.symbol, value};
      }), 'value'));

      if (ipcListEnabled) {
        setIpcListSearch({});
      }
    }
  }, [ipcListSearch, ipcListEnabled, ipcList.data, ipcList.status.hasError]);

  useEffect(() => {
    if (!collectionListEnabled || collectionList.data || collectionList.status.hasError) {
      collectionListSearch.callback?.((collectionList.data ?? []).map((c) => ({
        collection: c,
        label: c.name,
        value: +c.collectionId
      })));
      if (collectionListEnabled) {
        setCollectionListSearch({});
      }
    }
  }, [collectionListSearch, collectionListEnabled, collectionList.data, collectionList.status.hasError]);

  useEffect(() => {
    if (!sourceListEnabled || sourceList.data || sourceList.status.hasError) {
      sourceListSearch.callback?.((sourceList.data ?? []).map((s) => ({
        source: s,
        label: s.name,
        value: +s.sourceId
      })));
      if (sourceListEnabled) {
        setSourceListSearch({});
      }
    }
  }, [sourceListSearch, sourceListEnabled, sourceList.data, sourceList.status.hasError]);

  useEffect(() => {
    if (!linkSuggestionEnabled || linkSuggestionList.data || linkSuggestionList.status.hasError) {
      const ls = linkSuggestionList.status.hasError ? {} : linkSuggestions;

      Object.keys(linkSuggestionSearch?.links ?? {}).forEach((link) => {
        const suggestions = ls?.[link] ?? [];
        linkSuggestionSearch.links[link].callback?.(suggestions);
      });
      if (linkSuggestionEnabled) {
        setLinkSuggestionSearch({});
      }
    }
  }, [linkSuggestionSearch, linkSuggestionEnabled,
    linkSuggestionList.status.hasError, linkSuggestionList.data, linkSuggestions]);

  useEffect(() => {
    if (!cocNumberSuggestionEnabled || cocNumberSuggestionList.data || cocNumberSuggestionList.status.hasError) {
      cocNumberSuggestionSearch.callback?.((cocNumberSuggestionList.data?.data ?? [])
        .map((coc) => ({
          label: coc.cocNumber,
          value: coc.cocNumber,
          title: coc.name,
          subtitle: utils.address2String(coc.address),
          number: coc.cocNumber
        })));
      if (cocNumberSuggestionEnabled) {
        setCocNumberSuggestionSearch({});
      }
    }
  }, [cocNumberSuggestionSearch, cocNumberSuggestionEnabled, cocNumberSuggestionList.data, cocNumberSuggestionList.status.hasError]);

  return useMemo(() => {
    return {
      ...clientCallbacks,
      names: ({search, ids, filter, callback}) => {
        setNameListSearch({search, ids, filter, callback});
      },
      tags: ({search, ids, filter, callback}) => {
        setTagListSearch({search, ids, filter, callback});
      },
      cpcs: ({search, ids, filter, callback}) => {
        setCpcListSearch({search, ids, filter, callback});
      },
      ipcs: ({search, ids, filter, callback}) => {
        setIpcListSearch({search, ids, filter, callback});
      },
      collections: ({search, ids, filter, callback}) => {
        setCollectionListSearch({search, ids, filter, callback});
      },
      sources: ({search, ids, filter, callback}) => {
        setSourceListSearch({search, ids, filter, callback});
      },
      linkSuggestions: ({search, ids, filter, context, callback}) => {
        setLinkSuggestionSearch((current) => ({
          ...current,
          search: search ? search :
            context?.name ? `"${context.name}"${context?.country ? ` ${context.country.toUpperCase()}` : ''}`  : '',
          name: context?.name,
          ids, filter,
          links: {
            ...current.links,
            [context?.link]: {callback}
          }
        }));
      },
      cocNumberSuggestions: ({search, ids, filter, context, callback}) => {
        setCocNumberSuggestionSearch((current) => ({
          ...current,
          search: search,
          name: context?.legalName || context?.name,
          country: context?.country,
          ids, filter,
          callback
        }));
      },
    }
  }, [clientCallbacks]);
}

export function getEntityCsi (entity, teamId) {
  if (utils.isDefined(teamId) && entity?.csi) {
    const csiArray = utils.toArray(entity.csi)
      .concat(entity.dsi)
      .map((csi) => utils.camelcaseEx(csi));

    return csiArray.find((csi) => csi.clientId === teamId);
  } else {
    return null;
  }
}

export function useEntityCsi (entity) {
  const teamId = useAuthTeamId();

  return useMemo(() => {
    return getEntityCsi(entity, teamId);
  }, [teamId, entity]);
}

export function useEntityDealLeader (entity) {
  const csi = useEntityCsi(entity);
  return useMemo(() => utils.camelcase(csi?.leader), [csi?.leader]);
}

export function useEntityRelevancy (entity, collection = null) {
  const csi = useEntityCsi(entity);

  return useMemo(() => {
    if (csi) {
      if (utils.isDefined(collection?.collectionId)) {
        return csi.collectionRelevancyClient;
      } else {
        return csi.relevancyClient;
      }
    } else {
      return null;
    }
  }, [csi, collection]);
}

export function useEntityRelevancies (entity, collection = null) {
  const csi = useEntityCsi(entity);
  const collectionId = collection?.collectionId;

  return useMemo(() => {
    if (csi?.kvstorage?.clientRelevancies?.length > 0) {
      let relevancies;
      if (collectionId) {
        relevancies = csi.kvstorage.clientRelevancies.filter((r) => {
          return +r.userId > 0 && +r.collectionId === +collectionId;
        });
      } else {
        relevancies = csi.kvstorage.clientRelevancies.filter((r) => {
          return +r.userId > 0 && !r.collectionId;
        });
      }
      return relevancies || null;
    } else {
      return null;
    }
  }, [collectionId, csi]);
}

export function useEntityPersonalRelevancy (entity, collection = null) {
  const csi = useEntityCsi(entity);
  const userId = useAuthUserId();
  const collectionId = collection?.collectionId;

  return useMemo(() => {
    if (csi?.kvstorage?.clientRelevancies?.length > 0) {
      let relevancy;
      if (collectionId) {
        relevancy = csi.kvstorage.clientRelevancies.find((r) => {
          return +r.userId === +userId && +r.collectionId > 0 && +r.collectionId === +collectionId;
        });
      } else {
        relevancy = csi.kvstorage.clientRelevancies.find((r) => {
          return +r.userId === +userId && !r.collectionId;
        });
      }
      return (relevancy && relevancy.relevancy) || null;
    } else {
      return null;
    }
  }, [collectionId, csi, userId]);
}

export function useEntityExternalRelevancy (entity, collection = null) {
  const csi = useEntityCsi(entity);
  const collectionId = collection?.collectionId;

  return useMemo(() => {
    if (csi) {
      if (collectionId) {
        return csi.collectionRelevancyUs;
      } else {
        return csi.relevancyUs;
      }
    } else {
      return null;
    }
  }, [csi, collectionId]);
}

export function useEntityLinks (entity) {
  return useMemo(() => {
    return entity?.links?.filter((l) => Boolean(l.value)).map((l) => {
      const linkDef = constants.data.lookup('links', l.name.toLowerCase());
      return {
        ...utils.camelcase(l),
        url: l.value,
        ...linkDef
      }
    });
  }, [entity?.links]);
}

export function useLinkSuggestions (data, name) {
  return useMemo(() => {
    const processGoogle = (link, google) => {
      let cleanLink = !link.clean ? google.link : utils.cleanUrl(google.link);
      let cleanValue = !link.clean ? google.link : (
        link.value === 'website' ? utils.cleanWebsite(google.link) : utils.cleanUrl(google.link)
      );

      return {
        label: cleanValue,
        value: cleanValue,
        title: google.title ?? google.displayLink,
        subtitle: google.snippet,
        icon: link.icon,
        button: 'Use URL',
        link: cleanLink
      }
    }

    if (data) {
      const options = {};
      data.forEach((google) => {
        let added = false;
        constants.data.links.forEach((link) => {
          options[link.value] = options[link.value] ?? [];

          let found = false;
          if (link.value !== 'link') {
            if (link.value === 'website') {
              if (utils.nameWebsiteMatch(name, google.link)) {
                found = true;
              }
            } else {
              if (google.labels && google.labels.find((l) => l.name?.toLowerCase() === link.value)) {
                found = true;
              } else if (link.regexp && google.link?.match?.(link.regexp)) {
                found = true;
              } else if (utils.url(google.link)?.hostname?.split('.').includes(link.hostname ?? link.value)) {
                found = true;
              }

              if (found && link.value === 'linkedin') {
                found = Boolean(utils.cleanLinkedInHandle(google.link));
              }
            }
          }

          if (found) {
            added = true;
            options[link.value].push(processGoogle(link, google));
          }
        });

        const other = constants.data.links.find((l) => l.value === 'link')
        if (!added && other) {
          options[other.value].push(processGoogle(other, google));
        }
      });

      Object.keys(options).forEach((k) => {
        options[k] = utils.uniqueArray(options[k], 'value');
      });

      return options;
    }
  }, [data, name]);
}

export function useEntityPatch () {
  const entityUpdate = useEntityUpdate();

  return useCallback((entity, changes) => {
    changes = (Object.keys(changes).length > 1) ? utils.changedFormFields(entity, changes) : changes;

    if (!utils.isEmpty(changes)) {
      return entityUpdate.mutation.mutateAsync({
        entityId: entity.entityId,
        ...changes
      });
    } else {
      return Promise.resolve();
    }
  }, [entityUpdate.mutation]);
}

export function useEntitySelected (clientId) {
  const [state, setState] = useState({});

  const selectedEnabled = utils.isDefined(state.listState);
  const selected = useEntityList({
    clientId,
    search: state.listState?.search,
    filter: state.listState?.filter,
    sort: state.listState?.sort,
    page: 0,
    pageSize: state.count,
    minimal: true
  }, {
    ...constants.queryOptions.runOnceNotStale,
    overrideDataType: constants.dataTypes.other,
    enabled: selectedEnabled && (clientId >= 0)
  });

  useEffect(() => {
    if (!selectedEnabled || selected.data || selected.status.hasError) {
      if (!selected.status.hasError) {
        state.success?.(selected.data);
      } else {
        state.error?.();
      }
      if (selectedEnabled) {
        setState({});
      }
    }
  }, [state, selectedEnabled, selected.data, selected.status.hasError]);

  return useCallback((listState, count, success, error) => {
    setState({listState, count, success, error});
  }, []);
}

// groups, columns and other definitions
export function useEntityGroups (view, collection, customFields) {
  const tagGroups = collection?.tagGroups;

  return useMemo(() => {
    if (customFields && tagGroups) {
      const groups = [];

      Object.keys(constants.entity.groupDefinition).forEach((k) => {
        if (!utils.isFunction(constants.entity.groupDefinition[k])) {
          if (!groups.find((g) => g.name === k)) {
            const groupDef = constants.groupDefinition.lookup('entity', view, k, false, utils.mergeObjects);

            groups.push({
              ...groupDef,
              name: k,
              position: groupDef.position ??
                groups.reduce((p, g) => Math.max(p, g.position), 0),
              fields: utils.object2Array(groupDef.fields).map((f, idx) => ({
                ...f,
                entity: 'entity',
                position: f.position ?? idx,
                auth: {
                  read: utils.createAuth({attribute:`entity.field.${f.name}.read`}),
                  update: utils.createAuth({attribute:`entity.field.${f.name}.update`})
                },
                required: f.required ?? false
              })) ?? []
            });
          }
        }
      });

      let group = groups.find((g) => g.name === 'clientTags');
      const hasPoints = tagGroups?.some((tg) => tg.hasPoints);
      group.fields = group.fields.filter((f) => hasPoints || !f.has?.includes('points'));

      const lastPos = group.fields.reduce((p, f) => Math.max(p, f.position), 0);
      tagGroups?.forEach((tg) => {
        group.fields.push({
          name: `tagGroup${tg.groupId}`,
          tagGroupId: tg.groupId,
          tagGroup: tg,
          variant: 'tagGroups',
          entity: 'entity',
          position: lastPos + +(tg.pos || 1),
          auth: {
            read: utils.createAuth({attribute: 'entity.field.clientTags.read'}),
            update: utils.createAuth({attribute: 'entity.field.clientTags.update'}),
          }
        });
      });

      [...customFields].sort((a, b) => {
        const pos = +a.position - +b.position;
        return pos ? pos : (a.label.localeCompare(b.value) ? a.label.localeCompare(b.value) : +a.fieldId - +b.fieldId);
      })
        .forEach((cf) => {
          const groupDef = constants.groupDefinition.lookup('entity', view, cf.name, true, utils.mergeObjects);
          const fieldDef = groupDef?.fields?.[Object.keys(groupDef?.fields || {}).find((k) => k === utils.camelcase(cf.name))];

          if (utils.isDefined(groupDef?.name ?? cf.groupName)) {
            let group = groups.find((g) => g.name === (groupDef?.name ?? utils.camelcase(cf.groupName)));
            if (!group || group.locked) {
              const gd = constants.groupDefinition.lookup('entity', view, groupDef?.name ?? cf.groupName, false, utils.mergeObjects);
              const lastPos = groups.reduce((p, g) => Math.max(p, g.position ?? 0), 0);

              group = {
                ...gd,
                ...groupDef,
                locked: false,
                name: gd?.name ?? utils.camelcase(cf.groupName),
                title: gd?.title ?? utils.upperFirst(cf.groupName),
                position: +(cf.groupPosition ?? gd?.position ?? (lastPos + 1)),
                fields: []
              };
              groups.push(group);
            } else {
              group.position = +(cf.groupPosition ?? group.position);
            }

            const lastPos = group.fields.reduce((p, f) => Math.max(p, f.position ?? 0), 0);
            const fieldIndex = group.fields.findIndex((f) => f.name === utils.camelcase(cf.name));
            const isLink = cf.dataType === constants.fieldDataTypes.url;
            const field = {
              ...fieldDef,
              customField: cf,
              name: cf.name,
              entity: 'entity',
              link: isLink ? constants.data.lookup('links', cf.name) : null,
              context: isLink ? {
                link: constants.data.lookup('links', cf.name)?.value
              } : null,
              variant: fieldDef?.variant ?? (isLink ? 'links' : 'detail'),
              options: fieldDef?.options ?? (isLink ? 'linkSuggestions' : null),
              auth: {
                read: utils.createAuth({attribute:`entity.field.${utils.camelcase(cf.name)}.read`}),
                update: utils.createAuth({attribute:`entity.field.${utils.camelcase(cf.name)}.update`})
              },
              position: +(cf.position ?? fieldDef?.position ?? (lastPos + 1)),
              required: fieldDef?.required ?? false
            };

            if (fieldIndex !== -1) {
              group.fields[fieldIndex] = {
                ...group.fields[fieldIndex],
                ...field
              }
            } else {
              group.fields.push(field);
            }
          }
        });

      return groups;
    } else {
      return null;
    }
  }, [tagGroups, customFields, view]);
}

export function useEntityColumns (view, collection, customFields) {
  const tagGroups = collection?.tagGroups;

  return useMemo(() => {
    let columns = [];
    if (customFields) {
      const presetDefs = constants.presetDefinition.lookup('entity', view);

      Object.keys(constants.entity.columnDefinition).forEach((k) => {
        if (!utils.isFunction(constants.entity.columnDefinition[k])) {
          const groupDef = constants.groupDefinition.lookup('entity', view, k, true, utils.mergeObjects);
          const fieldDef = groupDef?.fields?.[Object.keys(groupDef?.fields || {}).find((k1) => k1 === k)];
          const columnDef = constants.columnDefinition.lookup('entity', view, k, utils.mergeObjects);

          const lastPos = columns.reduce((p, c) => Math.max(p, c.position), 0);
          const firstPosInGroup = columns.reduce((p, c) => (groupDef?.name && c.group?.name === groupDef?.name) ?
            (!p ? c.position : p) : p, groupDef?.name ? 0 : null);
          const lastPosInGroup = columns.reduce((p, c) => (groupDef?.name && c.group?.name === groupDef?.name) ?
            (+p + 1) : p, groupDef?.name ? 0 : null);
          const groupPos = +(groupDef?.tablePosition ?? groupDef?.position ?? (
            ((firstPosInGroup ?? 0) === 0 && lastPos > 0) ? (lastPos + constants.numbers.position.groupGap) :
              ((firstPosInGroup > 0) ? (firstPosInGroup - 1) : constants.numbers.position.maxPosition)
          )) * constants.numbers.position.groupGap;
          const fieldPos = (utils.isDefined(fieldDef?.position ?? lastPosInGroup) ? (groupPos + (fieldDef?.position ?? (lastPosInGroup + 1))) : (lastPos + 1));
          if (!columns.find((c) => c.name === k)) {
            columns.push({
              ...fieldDef,
              ...columnDef,
              id: columnDef.sortingKey ?? k,
              name: k,
              entity: 'entity',
              hidden: columnDef?.hidden,
              header: columnDef?.header ?? fieldDef?.label,
              group: columnDef.group ?? groupDef,
              presets: columnDef.presets ?? presetDefs,
              position: columnDef.position ? columnDef.position : fieldPos,
              fieldPosition: fieldDef?.position ?? lastPosInGroup,
              auth: {
                read: utils.createAuth({attribute:`entity.column.${k}.read`}),
                update: utils.createAuth({attribute:`entity.column.${k}.update`})
              }
            });
          }
        }
      });

      [...customFields].sort((a, b) => {
        const pos = +a.position - +b.position;
        return pos ? pos : (a.label.localeCompare(b.value) ? a.label.localeCompare(b.value) : +a.fieldId - +b.fieldId);
      })
        .forEach((cf) => {
          let groupDef = constants.groupDefinition.lookup('entity', view, cf.name, true, utils.mergeObjects) ??
            constants.groupDefinition.lookup('entity', view, cf.groupName, false, utils.mergeObjects);
          // custom fields always have groups
          groupDef = {
            ...groupDef,
            name: groupDef?.name ?? utils.camelcase(cf.groupName),
            title: groupDef?.title ?? utils.upperFirst(cf.groupName)
          };

          const fieldDef = groupDef?.fields?.[Object.keys(groupDef?.fields || {})
            .find((k) => k === utils.camelcase(cf.name))];
          const columnDef = constants.columnDefinition.lookup('entity', view, cf.name, utils.mergeObjects);

          if (!['markdown', 'textarea'].includes(cf.renderer)) {
            const lastPos = columns.reduce((p, c) => Math.max(p, c.position), 0);
            const firstPosInGroup = columns.reduce((p, c) => (groupDef?.name && c.group?.name === groupDef?.name) ?
              (!p ? c.position : p) : p, groupDef?.name ? 0 : null);
            const lastPosInGroup = columns.reduce((p, c) => (groupDef?.name && c.group?.name === groupDef?.name) ?
              (+p + 1) : p, groupDef?.name ? 0 : null);
            const groupPos = +(cf.groupPosition ?? groupDef?.tablePosition ?? groupDef?.position ?? (
              ((firstPosInGroup ?? 0) === 0 && lastPos > 0) ? (lastPos + constants.numbers.position.groupGap) :
              ((firstPosInGroup > 0) ? (firstPosInGroup - 1) : constants.numbers.position.maxPosition)
            )) * constants.numbers.position.groupGap;
            let columnIndex = columns.findIndex((c) => c.name === utils.camelcase(cf.name));
            if (columnIndex === -1) {
              columns.push({
                ...fieldDef,
                ...columnDef,
                entity: 'entity'
              });
              columnIndex = columns.length - 1;
            }
            const columnPos = (
              utils.isDefined(cf.position ?? fieldDef?.position ?? lastPosInGroup) ?
                (groupPos + (cf.position ?? fieldDef?.position ?? (lastPosInGroup + 1))) :
                (columns[columnIndex].position ?? (lastPos + 1))
            )

            const isLink = cf.dataType === constants.fieldDataTypes.url;
            columns[columnIndex] = {
              ...columns[columnIndex],
              id: columns[columnIndex].sortingKey ?? cf.name,
              name: cf.name,
              group: columns[columnIndex].group ?? groupDef,
              presets: columns[columnIndex].presets ?? presetDefs,
              header: columns[columnIndex].header ?? cf.label,
              customField: cf,
              link: isLink ? constants.data.lookup('links', cf.name) : null,
              context: isLink ? {
                link: constants.data.lookup('links', cf.name)?.value
              } : null,
              options: columns[columnIndex].options ?? (isLink ? 'linkSuggestions' : null),
              auth: {
                read: utils.createAuth({attribute: `entity.column.${utils.camelcase(cf.name)}.read`}),
                update: utils.createAuth({attribute: `entity.column.${utils.camelcase(cf.name)}.update`})
              },
              position: columnDef?.position ? columnDef.position : columnPos,
              fieldPosition: columns[columnIndex].fieldPosition ?? fieldDef?.position ?? lastPosInGroup,
              visible: columns[columnIndex].visible ?? false,
              sortable: columnDef?.sortable ?? true,
              required: columnDef?.required ?? false
            };
          }
        });

      const hasPoints = tagGroups?.some((tg) => tg.hasPoints);
      columns = columns.filter((c) => hasPoints || !c.has?.includes('points'));

      tagGroups?.forEach((tg) => {
        const groupDef = constants.groupDefinition.lookup('entity', view, 'clientTags', false, utils.mergeObjects);
        const groupPos = +(groupDef?.tablePosition ?? groupDef?.position ?? constants.numbers.position.maxPosition) * constants.numbers.position.groupGap;
        const lastPos = Object.keys(groupDef.fields || {}).reduce((p, k) => Math.max(p, groupDef.fields[k].tablePosition ?? groupDef.fields[k].position), 0);
        columns.push({
          id: `dropdown_${tg.groupId}`, // sortingKey
          name: `tagGroup${tg.groupId}`,
          entity: 'entity',
          group: groupDef ? {
            name: 'clientTags',
            ...groupDef
          } : null,
          presets: presetDefs,
          sortingKey: `dropdown_${tg.groupId}`,
          header: tg.name,
          tagGroupId: tg.groupId,
          tagGroup: tg,
          position: lastPos + groupPos + (tg.pos || 1),
          fieldPosition: tg.pos,
          required: false,
          auth: {
            read: utils.createAuth({attribute: 'entity.column.clientTags.read'}),
            update: utils.createAuth({attribute: 'entity.column.clientTags.update'}),
          },
          FormFieldProps: {
            ListProps: {
              catchFocus: true
            },
            ChipListProps: {
              variant: 'compact'
            }
          }
        });
      });

      return columns;
    } else {
      return null;
    }
  }, [customFields, tagGroups, view]);
}

export function useEntityFilterGroups (view, collection, customFields, clientId) {
  const tagGroups = collection?.tagGroups;

  return useMemo(() => {
    const filterGroups = [];
    if (customFields && tagGroups) {
      Object.keys(constants.entity.filterGroupDefinition).forEach((k) => {
        if (!utils.isFunction(constants.entity.filterGroupDefinition[k])) {
          const groupDef = constants.filterGroupDefinition.lookup('entity', view, k, false, utils.mergeObjects);
          const lastPos = filterGroups.reduce((p, g) => Math.max(p, g.position ?? 0), 0);
          filterGroups.push({
            ...groupDef,
            name: k,
            position: (groupDef.position ?? (lastPos + 1)),
            filters: Object.keys(groupDef.filters ?? {}).map((k, idx) => {
              const f = groupDef.filters[k];
              return {
                ...f,
                id: f.filterKey ?? k,
                position: f.position ?? (idx + 1),
                name: k,
                entity: 'entity',
                auth: {
                  read: utils.createAuth({attribute: `entity.filter.${k}.read`}),
                  update: utils.createAuth({attribute: `entity.filter.${k}.update`}),
                }
              }
            })
          });
        }
      });

      let groupDef = constants.filterGroupDefinition.lookup('entity', view, 'customFields', true, utils.mergeObjects);
      let group = filterGroups.find((g) => g.name === groupDef.name);
      let filter = group.filters.find((f) => f.name === 'customFields');
      group.filters = group.filters.filter((f) => f.name !== 'customFields');

      let filterPos = +(filter?.position ?? constants.numbers.position.maxPosition);
      [...customFields].sort((a, b) => {
        const pos = +a.position - +b.position;
        return pos ? pos : (a.label.localeCompare(b.value) ? a.label.localeCompare(b.value) : +a.fieldId - +b.fieldId);
      })?.forEach((cf) => {
        const typeToFilterProps = () => {
          if (cf.renderer === constants.fieldRenderers.monetary) {
            return {
              monetary: true,
              type: constants.formFieldTypes.list,
              conversion: constants.formFieldConversionTypes.value,
              validation: constants.formFieldValidationTypes.list,
              defaultOptions: [{
                label: 'No data',
                value: '0'
              }],
              options: 'moneyRange',
              FormFieldProps: {
                multiple: true
              }
            }
          } else if (cf.renderer === constants.fieldRenderers.numeric) {
            return {
              type: constants.formFieldTypes.list,
              conversion: constants.formFieldConversionTypes.value,
              validation: constants.formFieldValidationTypes.list,
              defaultOptions: [{
                label: 'No data',
                value: '0'
              }],
              options: 'mediumRange',
              FormFieldProps: {
                multiple: true
              }
            }
          } else {
            return {};
          }
        }

        if (+cf.clientId > 0 && +cf.clientId === +clientId) {
          if ([constants.fieldRenderers.text,
            constants.fieldRenderers.numeric,
            constants.fieldRenderers.monetary].includes(cf.renderer)) {
            filterPos += 1;
            group.filters.push({
              ...filter,
              id: cf.name, // filterKey
              name: cf.name,
              label: cf.label,
              filterKey: cf.name,
              customField: cf,
              description: '',
              position: +(cf.position ?? filterPos),
              auth: {
                read: utils.createAuth({attribute: 'entity.filter.customFields.read'}),
                update: utils.createAuth({attribute: 'entity.filter.customFields.update'}),
              },
              ...(typeToFilterProps())
            });
          }
        }
      });

      // lookup group for name
      groupDef = constants.filterGroupDefinition.lookup('entity', view, 'clientTags', true, utils.mergeObjects);
      group = filterGroups.find((g) => g.name === groupDef.name);
      filter = group.filters.find((f) => f.name === 'clientTags');
      group.filters = group.filters.filter((f) => f.name !== 'clientTags');

      filterPos = +(filter?.position ?? constants.numbers.position.maxPosition);
      tagGroups?.forEach((tg) => {
        group.filters.push({
          ...filter,
          id: `group_${tg.groupId}`, // filterKey
          name: `tagGroup${tg.groupId}`,
          label: tg.name,
          validation: `${constants.formFieldValidationTypes.list}(${constants.formFieldValidationTypes.int})`,
          filterKey: `group_${tg.groupId}`,
          type: constants.formFieldTypes.list,
          tagGroupId: tg.groupId,
          tagGroup: tg,
          position: (filterPos + tg.pos),
          auth: {
            read: utils.createAuth({attribute: 'entity.filter.clientTags.read'}),
            update: utils.createAuth({attribute: 'entity.filter.clientTags.update'}),
          },
          FormFieldProps: {
            multiple: true
          }
        });
      });

      return filterGroups;
    } else {
      return null;
    }
  }, [customFields, tagGroups, view, clientId]);
}

export function useEntityPanels (view, client) {
  const groups = client?.dealflowGroups;

  return useMemo(() => {
    if (groups) { // any view
      return groups.map((group) => ({
        name: group.groupId.toString(),
        id: +group.groupId,
        title: group.label,
        color: utils.deprecatedColor(group.color),
        position: group.position,
        subPanels: group.statuses.reduce((a, status) => {
          if (+status.statusId !== 0) {
            a.push({
              name: status.statusId.toString(),
              id: +status.statusId,
              title: status.name,
              color: utils.deprecatedColor(group?.color ? group.color : status?.color),
              position: status.position,
            });
          }
          return a;
        }, [])
      })).filter((p) => p.subPanels.length > 0);
    }
  }, [groups]);
}

export function useEntitySections (view) {
  return useMemo(() => {
    const sections = [];
    Object.keys(constants.entity.sectionDefinition).forEach((k) => {
      if (!utils.isFunction(constants.entity.sectionDefinition[k])) {
        const sectionDef = constants.sectionDefinition.lookup('entity', view, k, utils.mergeObjects);
        const lastPos = sections.reduce((p, s) => Math.max(p, s.position ?? 0), 0);
        sections.push({
          ...sectionDef,
          name: k,
          position: (sectionDef.position ?? (lastPos + 1)),
          auth: {
            read: utils.createAuth({attribute: `entity.section.${sectionDef.name}.read`}),
            update: utils.createAuth({attribute: `entity.section.${sectionDef.name}.update`})
          },
          cards: utils.object2Array(sectionDef?.cards).map((cardDef) => ({
            ...cardDef,
            auth: {
              read: utils.createAuth({attribute: `entity.section.${cardDef.name}.read`}),
              update: utils.createAuth({attribute: `entity.section.${cardDef.name}.update`})
            }
          }))
        });
      }
    });

    return sections;
  }, [view]);
}


export function useEntityGraphs (view) {
  return useMemo(() => {
    const graphs = [];
    Object.keys(constants.entity.graphDefinition).forEach((k) => {
      if (!utils.isFunction(constants.entity.graphDefinition[k])) {
        const graphDef = constants.graphDefinition.lookup('entity', view, k, utils.mergeObjects);
        const lastPos = graphs.reduce((p, s) => Math.max(p, s.position ?? 0), 0);
        graphs.push({
          ...graphDef,
          name: k,
          position: (graphDef.position ?? (lastPos + 1)),
          auth: {
            read: utils.createAuth({attribute: `entity.graph.${graphDef.name}.read`}),
            update: utils.createAuth({attribute: `entity.graph.${graphDef.name}.update`})
          }
        });
      }
    });

    return graphs;
  }, [view]);
}

export function useEntitySettings (collectionId = null) {
  const authorize = useAuthorize();
  const entityProfileCallbacks = useEntityCallbacks();

  const client = useAuthClient();
  const clientCustomFields = useClientCustomFieldCache({clientId: client?.clientId},
    {enabled: client?.clientId >= 0});
  const collectionCustomFields = useCollectionCustomFieldCache({clientId: client?.clientId, collectionId: collectionId},
    {enabled: client?.clientId >= 0 && collectionId > 0});

  const collection = useCollectionGet({collectionId: collectionId ?? client?.universeCollectionId},
    {enabled: Boolean(collectionId ?? client?.universeCollectionId)});

  const fieldData = useMemo(() => ({
    customFields: ((clientCustomFields.data?.data ?? clientCustomFields.data) ?? [])
      .concat((collectionCustomFields.data?.data ?? collectionCustomFields.data) ?? []),
    tagGroups: collection?.data?.tagGroups,
    callbacks: entityProfileCallbacks
  }), [entityProfileCallbacks, clientCustomFields.data, collectionCustomFields.data, collection?.data?.tagGroups]);

  const entityFilterGroups = useEntityFilterGroups(null, collection?.data, fieldData.customFields, client?.clientId);

  const filterGroups = useMemo(() => {
    if (entityFilterGroups?.length > 0) {
      return entityFilterGroups
        .filter((filterGroupDef) => !filterGroupDef.hidden && authorize(filterGroupDef.auth?.read ?? {}))
        .map((filterGroupDef) => {
          filterGroupDef.filters = (filterGroupDef.filters ?? [])
            .filter((f) => !f.hidden && authorize(f.auth?.read ?? {}));
          return filterGroupDef;
        });
    } else {
      return null;
    }
  }, [authorize, entityFilterGroups]);

  return [fieldData, filterGroups, collection?.data, fieldData.customFields, client];
}

// dealflow
export function useDealflowEntityGroups (view) {
  return useMemo(() => {
    const groups = [];

    Object.keys(constants.dealflow.entity.groupDefinition).forEach((k) => {
      if (!utils.isFunction(constants.dealflow.entity.groupDefinition[k])) {
        if (!groups.find((g) => g.name === k)) {
          const groupDef = constants.groupDefinition.lookup('dealflow.entity', view, k, false, utils.mergeObjects);

          groups.push({
            ...groupDef,
            name: k,
            position: groupDef.position ??
              groups.reduce((p, g) => Math.max(p, g.position), 0),
            fields: utils.object2Array(groupDef.fields).map((f, idx) => ({
              ...f,
              entity: 'entity',
              position: f.position ?? idx,
              auth: {
                read: utils.createAuth({attribute:`dealflow.entity.field.${f.name}.read`}),
                update: utils.createAuth({attribute:`dealflow.entity.field.${f.name}.update`})
              },
              required: f.required ?? false
            })) ?? []
          });
        }
      }
    });

    return groups;
  }, [view]);
}

export function useDealflowEntityColumns (view) {
  return useMemo(() => {
    const columns = [];
    Object.keys(constants.dealflow.entity.columnDefinition).forEach((k) => {
      if (!utils.isFunction(constants.dealflow.entity.columnDefinition[k])) {
        const groupDef = constants.groupDefinition.lookup('dealflow.entity', view, k, true, utils.mergeObjects);
        const presetDefs = constants.presetDefinition.lookup('dealflow.entity', view);
        const fieldDef = groupDef?.fields?.[Object.keys(groupDef?.fields || {})
          .find((k1) => k1 === k)];
        const columnDef = constants.columnDefinition.lookup('dealflow.entity', view, k, utils.mergeObjects);

        const lastPos = columns.reduce((p, c) => Math.max(p, c.position), 0);
        const firstPosInGroup = columns.reduce((p, c) => (groupDef?.name && c.group?.name === groupDef?.name) ?
          (!p ? c.position : p) : p, groupDef?.name ? 0 : null);
        const lastPosInGroup = columns.reduce((p, c) => (groupDef?.name && c.group?.name === groupDef?.name) ?
          (+p + 1) : p, groupDef?.name ? 0 : null);
        const groupPos = +(groupDef?.tablePosition ?? groupDef?.position ?? (
          ((firstPosInGroup ?? 0) === 0 && lastPos > 0) ? (lastPos + constants.numbers.position.groupGap) :
            ((firstPosInGroup > 0) ? (firstPosInGroup - 1) : constants.numbers.position.maxPosition)
        )) * constants.numbers.position.groupGap;
        const fieldPos = (utils.isDefined(fieldDef?.position ?? lastPosInGroup) ? (groupPos + (fieldDef?.position ?? (lastPosInGroup + 1))) : (lastPos + 1));
        if (!columns.find((c) => c.name === k)) {
          columns.push({
            ...fieldDef,
            ...columnDef,
            id: columnDef.sortingKey ?? k,
            name: k,
            entity: 'entity',
            hidden: columnDef?.hidden,
            header: columnDef?.header ?? fieldDef?.label,
            group: columnDef.group ?? groupDef,
            presets: columnDef.presets ?? presetDefs,
            position: columnDef.position ? columnDef.position : fieldPos,
            fieldPosition: fieldDef?.position ?? lastPosInGroup,
            auth: {
              read: utils.createAuth({attribute:`dealflow.entity.column.${k}.read`}),
              update: utils.createAuth({attribute:`dealflow.entity.column.${k}.update`})
            }
          });
        }
      }
    });

    return columns;
  }, [view]);
}

export function useDealflowEntityFilterGroups (view) {
  return useMemo(() => {
    const filterGroups = [];
    Object.keys(constants.dealflow.entity.filterGroupDefinition).forEach((k) => {
      if (!utils.isFunction(constants.dealflow.entity.filterGroupDefinition[k])) {
        const groupDef = constants.filterGroupDefinition.lookup('dealflow.entity', view, k, false, utils.mergeObjects);
        const lastPos = filterGroups.reduce((p, g) => Math.max(p, g.position ?? 0), 0);
        filterGroups.push({
          ...groupDef,
          name: k,
          position: (groupDef.position ?? (lastPos + 1)),
          filters: Object.keys(groupDef.filters ?? {}).map((k, idx) => {
            const f = groupDef.filters[k];
            return {
              ...f,
              id: f.filterKey ?? k,
              name: k,
              position: f.position ?? (idx + 1),
              entity: 'entity',
              auth: {
                read: utils.createAuth({attribute: `dealflow.entity.filter.${k}.read`}),
                update: utils.createAuth({attribute: `dealflow.entity.filter.${k}.update`}),
              }
            }
          })
        });
      }
    });

    return filterGroups;
  }, [view]);
}

export function useDealflowEntitySections (view) {
  return useMemo(() => {
    const sections = [];
    Object.keys(constants.dealflow.entity.sectionDefinition).forEach((k) => {
      if (!utils.isFunction(constants.dealflow.entity.sectionDefinition[k])) {
        const sectionDef = constants.sectionDefinition.lookup('dealflow.entity', view, k, utils.mergeObjects);
        const lastPos = sections.reduce((p, s) => Math.max(p, s.position ?? 0), 0);
        sections.push({
          ...sectionDef,
          name: k,
          position: (sectionDef.position ?? (lastPos + 1)),
          auth: {
            read: utils.createAuth({attribute: `dealflow.entity.section.${sectionDef.name}.read`}),
            update: utils.createAuth({attribute: `dealflow.entity.section.${sectionDef.name}.update`})
          },
          cards: utils.object2Array(sectionDef?.cards).map((cardDef) => ({
            auth: {
              read: utils.createAuth({attribute: `dealflow.entity.section.${cardDef.name}.read`}),
              update: utils.createAuth({attribute: `dealflow.entity.section.${cardDef.name}.update`})
            }
          }))
        });
      }
    });

    return sections;
  }, [view]);
}

export function useDealflowEntityGraphs (view) {
  return useMemo(() => {
    const graphs = [];
    Object.keys(constants.dealflow.entity.graphDefinition).forEach((k) => {
      if (!utils.isFunction(constants.dealflow.entity.graphDefinition[k])) {
        const graphDef = constants.graphDefinition.lookup('dealflow.entity', view, k, utils.mergeObjects);
        const lastPos = graphs.reduce((p, s) => Math.max(p, s.position ?? 0), 0);
        graphs.push({
          ...graphDef,
          name: k,
          position: (graphDef.position ?? (lastPos + 1)),
          auth: {
            read: utils.createAuth({attribute: `dealflow.entity.graph.${graphDef.name}.read`}),
            update: utils.createAuth({attribute: `dealflow.entity.graph.${graphDef.name}.update`})
          }
        });
      }
    });

    return graphs;
  }, [view]);
}

// database
export function useDatabaseEntitySelected (clientId) {
  const [state, setState] = useState({});

  const selectedEnabled = utils.isDefined(state.listState);
  const selected = useEntitySearch({
    clientId: clientId,
    query: state.listState?.query,
    page: 0,
    pageSize: state.count,
    minimal: true
  }, {
    ...constants.queryOptions.runOnceNotStale,
    overrideDataType: constants.dataTypes.other,
    enabled: selectedEnabled && (clientId >= 0)
  });

  useEffect(() => {
    if (!selectedEnabled || selected.data || selected.status.hasError) {
      if (!selected.status.hasError) {
        state.success?.(selected.data);
      } else {
        state.error?.();
      }
      if (selectedEnabled) {
        setState({});
      }
    }
  }, [state, selectedEnabled, selected.data, selected.status.hasError]);

  return useCallback((listState, count, success, error) => {
    setState({listState, count, success, error});
  }, []);
}

export function useDatabaseEntityGroups (view) {
  return useMemo(() => {
    const groups = [];

    Object.keys(constants.database.entity.groupDefinition).forEach((k) => {
      if (!utils.isFunction(constants.database.entity.groupDefinition[k])) {
        if (!groups.find((g) => g.name === k)) {
          const groupDef = constants.groupDefinition.lookup('database.entity', view, k, false, utils.mergeObjects);

          groups.push({
            ...groupDef,
            name: k,
            position: groupDef.position ??
              groups.reduce((p, g) => Math.max(p, g.position), 0),
            fields: utils.object2Array(groupDef.fields).map((f, idx) => ({
              ...f,
              entity: 'entity',
              position: f.position ?? idx,
              auth: {
                read: utils.createAuth({attribute:`database.entity.field.${f.name}.read`}),
                update: utils.createAuth({attribute:`database.entity.field.${f.name}.update`})
              },
              required: f.required ?? false
            })) ?? []
          });
        }
      }
    });

    return groups;
  }, [view]);
}

export function useDatabaseEntityColumns (view) {
  return useMemo(() => {
    const columns = [];
    Object.keys(constants.database.entity.columnDefinition).forEach((k) => {
      if (!utils.isFunction(constants.database.entity.columnDefinition[k])) {
        const groupDef = constants.groupDefinition.lookup('database.entity', view, k, true, utils.mergeObjects);
        const presetDefs = constants.presetDefinition.lookup('database.entity', view);
        const fieldDef = groupDef?.fields?.[Object.keys(groupDef?.fields || {})
          .find((k1) => k1 === k)];
        const columnDef = constants.columnDefinition.lookup('database.entity', view, k, utils.mergeObjects);

        const lastPos = columns.reduce((p, c) => Math.max(p, c.position), 0);
        const firstPosInGroup = columns.reduce((p, c) => (groupDef?.name && c.group?.name === groupDef?.name) ?
          (!p ? c.position : p) : p, groupDef?.name ? 0 : null);
        const lastPosInGroup = columns.reduce((p, c) => (groupDef?.name && c.group?.name === groupDef?.name) ?
          (+p + 1) : p, groupDef?.name ? 0 : null);
        const groupPos = +(groupDef?.tablePosition ?? groupDef?.position ?? (
          ((firstPosInGroup ?? 0) === 0 && lastPos > 0) ? (lastPos + constants.numbers.position.groupGap) :
            ((firstPosInGroup > 0) ? (firstPosInGroup - 1) : constants.numbers.position.maxPosition)
        )) * constants.numbers.position.groupGap;
        const fieldPos = (utils.isDefined(fieldDef?.position ?? lastPosInGroup) ? (groupPos + (fieldDef?.position ?? (lastPosInGroup + 1))) : (lastPos + 1));
        if (!columns.find((c) => c.name === k)) {
          columns.push({
            ...fieldDef,
            ...columnDef,
            id: columnDef.sortingKey ?? k,
            name: k,
            entity: 'entity',
            hidden: columnDef?.hidden,
            header: columnDef?.header ?? fieldDef?.label,
            group: columnDef.group ?? groupDef,
            presets: columnDef.presets ?? presetDefs,
            position: columnDef.position ? columnDef.position : fieldPos,
            fieldPosition: fieldDef?.position ?? lastPosInGroup,
            auth: {
              read: utils.createAuth({attribute:`database.entity.column.${k}.read`}),
              update: utils.createAuth({attribute:`database.entity.column.${k}.update`})
            }
          });
        }
      }
    });

    return columns;
  }, [view]);
}

export function useDealflowEntityPanels (view) {
  return useMemo(() => {
    if (!view || view) { // any view
      return [];
    }
  }, [view]);
}

export function useDatabaseEntityFilterGroups (view, collection, customFields, clientId) {
  const tagGroups = collection?.tagGroups;

  return useMemo(() => {
    const filterGroups = [];
    if (customFields && tagGroups) {
      Object.keys(constants.database.entity.filterGroupDefinition).forEach((k) => {
        if (!utils.isFunction(constants.database.entity.filterGroupDefinition[k])) {
          const groupDef = constants.filterGroupDefinition.lookup('database.entity', view, k, false, utils.mergeObjects);
          const lastPos = filterGroups.reduce((p, g) => Math.max(p, g.position ?? 0), 0);
          filterGroups.push({
            ...groupDef,
            name: k,
            position: (groupDef.position ?? (lastPos + 1)),
            filters: Object.keys(groupDef.filters ?? {}).map((k, idx) => {
              const f = groupDef.filters[k];
              return {
                ...f,
                id: f.filterKey ?? k,
                name: k,
                position: f.position ?? (idx + 1),
                entity: 'entity',
                auth: {
                  read: utils.createAuth({attribute: `database.entity.filter.${k}.read`}),
                  update: utils.createAuth({attribute: `database.entity.filter.${k}.update`}),
                }
              }
            })
          });
        }
      });

      let groupDef = constants.filterGroupDefinition.lookup('database.entity', view, 'customFields', true, utils.mergeObjects);
      let group = filterGroups.find((g) => g.name === groupDef.name);
      let filter = group.filters.find((f) => f.name === 'customFields');
      group.filters = group.filters.filter((f) => f.name !== 'customFields');


      let filterPos = +(filter?.position ?? constants.numbers.position.maxPosition);
      [...customFields].sort((a, b) => {
        const pos = +a.position - +b.position;
        return pos ? pos : (a.label.localeCompare(b.value) ? a.label.localeCompare(b.value) : +a.fieldId - +b.fieldId);
      })?.forEach((cf) => {
        const typeToFilterProps = () => {
          if (cf.renderer === constants.fieldRenderers.monetary) {
            return {
              defaultOptions: '',
              options: 'currencyConversions',
              renderer: constants.fieldRenderers.monetary,
              type: constants.formFieldTypes.autocomplete,
              format: constants.formFieldFormatTypes.int,
              validation: constants.formFieldValidationTypes.int,
              conversion: constants.formFieldConversionTypes.value,
              operators: constants.query.filterOperatorGroups.range
            }
          } else if (cf.renderer === constants.fieldRenderers.numeric) {
            return {
              defaultOptions: '',
              options: '',
              type: constants.formFieldTypes.text,
              format: constants.formFieldFormatTypes.int,
              validation: constants.formFieldValidationTypes.int,
              conversion: constants.formFieldConversionTypes.int,
              operators: constants.query.filterOperatorGroups.range
            }
          } else {
            return {};
          }
        }

        if (+cf.clientId > 0 && +cf.clientId === +clientId) {
          if ([constants.fieldRenderers.text,
            constants.fieldRenderers.numeric,
            constants.fieldRenderers.monetary].includes(cf.renderer)) {
            filterPos += 1;
            group.filters.push({
              ...filter,
              id: cf.name, // filterKey
              name: cf.name,
              label: cf.label,
              filterKey: cf.name,
              customField: cf,
              description: '',
              position: +(cf.position ?? filterPos),
              auth: {
                read: utils.createAuth({attribute: 'database.entity.filter.customFields.read'}),
                update: utils.createAuth({attribute: 'database.entity.filter.customFields.update'}),
              },
              ...(typeToFilterProps())
            });
          }
        }
      });

      // lookup group for name
      groupDef = constants.filterGroupDefinition.lookup('database.entity', view, 'clientTags', true, utils.mergeObjects);
      group = filterGroups.find((g) => g.name === groupDef.name);
      filter = group.filters.find((f) => f.name === 'clientTags');
      group.filters = group.filters.filter((f) => f.name !== 'clientTags');

      filterPos = +(filter?.position ?? constants.numbers.position.maxPosition);
      tagGroups?.forEach((tg) => {
        group.filters.push({
          ...filter,
          id: `group_${tg.groupId}`, // filterKey
          name: `tagGroup${tg.groupId}`,
          position: (filterPos + tg.pos),
          auth: {
            read: utils.createAuth({attribute: 'database.entity.filter.clientTags.read'}),
            update: utils.createAuth({attribute: 'database.entity.filter.clientTags.update'}),
          }
        });
      });

      return filterGroups;
    } else {
      return null;
    }
  }, [tagGroups, customFields, view, clientId]);
}

export function useDatabaseEntitySections (view) {
  return useMemo(() => {
    const sections = [];
    Object.keys(constants.database.entity.sectionDefinition).forEach((k) => {
      if (!utils.isFunction(constants.database.entity.sectionDefinition[k])) {
        const sectionDef = constants.sectionDefinition.lookup('database.entity', view, k, utils.mergeObjects);
        const lastPos = sections.reduce((p, s) => Math.max(p, s.position ?? 0), 0);
        sections.push({
          ...sectionDef,
          name: k,
          position: (sectionDef.position ?? (lastPos + 1)),
          auth: {
            read: utils.createAuth({attribute: `database.entity.section.${sectionDef.name}.read`}),
            update: utils.createAuth({attribute: `database.entity.section.${sectionDef.name}.update`})
          },
          cards: utils.object2Array(sectionDef?.cards).map((cardDef) => ({
            auth: {
              read: utils.createAuth({attribute: `database.entity.section.${cardDef.name}.read`}),
              update: utils.createAuth({attribute: `database.entity.section.${cardDef.name}.update`})
            }
          }))
        });
      }
    });

    return sections;
  }, [view]);
}

export function useDatabaseEntitySettings () {
  const authorize = useAuthorize();

  const [defaultFieldData, defaultFilterGroups, collection, customFields, client] = useEntitySettings();

  const databaseFieldData = useMemo(() => ({}), []);
  const fieldData = useMergeFieldData(defaultFieldData, databaseFieldData);

  const databaseFilterGroups = useDatabaseEntityFilterGroups(null, collection, customFields, client?.clientId);
  const filterGroupsMerged = useMergeFilterGroupDefinitions(defaultFilterGroups, databaseFilterGroups);

  const filterGroups = useMemo(() => {
    if (filterGroupsMerged?.length > 0) {
      return filterGroupsMerged
        .filter((filterGroupDef) => !filterGroupDef.hidden && authorize(filterGroupDef.auth?.read ?? {}))
        .map((filterGroupDef) => {
          filterGroupDef.filters = (filterGroupDef.filters ?? [])
            .filter((f) => !f.hidden && authorize(f.auth?.read ?? {}));
          return filterGroupDef;
        });
    } else {
      return null;
    }
  }, [authorize, filterGroupsMerged]);

  return [fieldData, filterGroups];
}


