import React, {useLayoutEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import Tabs from 'components/atoms/Tabs/Tabs/Tabs';
import Tab from 'components/atoms/Tabs/Tab/Tab';
import TabPanel from 'components/atoms/Tabs/TabPanel/TabPanel';
import Component from 'components/organisms/Utils/Component/Component';
import utils from 'helpers/utils';
import Box from 'components/atoms/Layout/Box/Box';
import {H6, P, Span} from 'components/atoms/Text/Typography/Typography';
import Toolbar from 'components/atoms/Toolbars/Toolbar/Toolbar';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Close from '@mui/icons-material/Close';
import Search from '@mui/icons-material/Search';
import ActionIconButton from 'components/molecules/Buttons/ActionIconButton/ActionIconButton';
import Clear from '@mui/icons-material/Clear';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import TabDivider from 'components/atoms/Dividers/TabDivider/TabDivider';
import Badge from 'components/atoms/Badges/Badge/Badge';
import Fab from 'components/atoms/Fabs/Fab/Fab';
import Tooltip from 'components/atoms/Tooltips/Tooltip/Tooltip';
import CircularProgress from 'components/atoms/Progress/CircularProgress/CircularProgress';
import StyledTableFilters from 'components/organisms/TableFilters/TableFilters/TableFilters.styles';
import TableFiltersContent from 'components/organisms/TableFilters/TableFiltersContent/TableFiltersContent';
import dom from 'helpers/dom';

const TableFilters = React.forwardRef((props, ref) => {
  const {
    count,
    filterGroups,
    filter,
    fieldData,
    onClose,
    onSubmit,
    onChange,
    isLoading,
    ...innerProps
  } = useComponentProps(props, 'TableFilters', {
    children: ['toolbar', 'tabs', 'panels']
  });

  const [internalState, setInternalState] = useState({
    searchValue: '',
    activeTab: false
  });

  const tableFilters = useMemo(() => ({
    state: {
      ...internalState
    }
  }), [internalState]);

  const tabGroupsMemo = useMemo(() => {
    let tabGroups = [];
    if (filterGroups && filter) {
      const filterObj = utils.filter2Object(filter);
      tabGroups = tabGroups.concat(filterGroups
        .filter((fg) => fg.filters?.length > 0)
        .map((fg) => {
          const filters = fg.filters.map((filter) => {
            const value = filterObj[filter.id];
            const initial = utils.isArray(value) ? (value?.length > 1 ? value : value?.[0]) : value;
            return {
              ...filter,
              initial,
              activeCount: utils.toArray(initial, true).length
            }
          });

          const activeCount = filters.filter((filter) => filter.activeCount > 0).length;

          return {
            name: fg.name,
            title: fg.title,
            position: fg.position,
            general: false,
            activeCount,
            filterGroups: [{
              ...fg,
              activeCount,
              filters: filters
                .filter((f) => !tableFilters.state.searchValue ||
                  f.label.toLowerCase().includes(tableFilters.state.searchValue.toLowerCase()))
                .sort((a, b) => a.position - b.position)
            }]
          }
        })
        .sort((a, b) => a.position - b.position));

      // add general filterGroups
      const allFilterGroups = tabGroups.reduce((a, tg) => a.concat(tg.filterGroups), []);
      const activeFilterGroups = allFilterGroups.filter((fg) => fg.activeCount > 0)
        .map((fg) => ({
          ...fg,
          filters: fg.filters.filter((f) => f.activeCount > 0)
        }));

      tabGroups.unshift({
        name: 'all',
        title: 'All filters',
        general: false,
        showTitles: true,
        activeCount: allFilterGroups.reduce((s, fg) => s + fg.activeCount, 0),
        filterGroups: allFilterGroups.filter((fg) => fg.filters.length > 0)
      });
      tabGroups.unshift({
        name: 'active',
        title: 'Active filters',
        general: true,
        showTitles: true,
        activeCount: activeFilterGroups.reduce((s, fg) => s + fg.activeCount, 0),
        filterGroups: activeFilterGroups.filter((fg) => fg.filters.length > 0)
      });

      tabGroups.forEach((tg, idx) => tg.id = idx);

      return tabGroups;
    } else {
      return null;
    }
  }, [filterGroups, tableFilters.state.searchValue, filter]);

  const onCloseEvent = useEffectEvent(onClose);
  const closeAction = useMemo(() => ({
    label: 'Close',
    tooltip: 'Close',
    icon: Close,
    onClick: (e) => {
      onCloseEvent?.(e, 'closeButtonClick');
    }
  }), [onCloseEvent]);

  useLayoutEffect(() => {
    if (tabGroupsMemo?.length > 0) {
      let activeTab = tableFilters.state.activeTab;
      if (tableFilters.state.activeTab === false) {
        activeTab = tabGroupsMemo.findIndex((tg) => tg.name === 'all');
      } else if (tableFilters.state.activeTab > (tabGroupsMemo?.length - 1)) {
        activeTab = (tabGroupsMemo?.length - 1);
      } else if (tableFilters.state.activeTab < 0) {
        activeTab = 0;
      }

      setInternalState(utils.updater({activeTab}, true));
    }
  }, [tabGroupsMemo, tableFilters.state.activeTab]);

  useLayoutEffect(() => {
    if (!isLoading) {
      setInternalState((current) => ({
        ...current,
        count
      }));
    }
  }, [count, isLoading]);

  const hasFilters = useMemo(() => {
    const tabGroup = tabGroupsMemo?.find?.((tg) => tg.id === tableFilters.state.activeTab);
    return tabGroup && tabGroup.filterGroups.reduce((c, fg) => c + fg.filters.length, 0) > 0;
  }, [tabGroupsMemo, tableFilters.state.activeTab]);

  const handleActiveTabChange = (event, value) => {
    setInternalState(utils.updater({
      activeTab: value
    }, true));
  };

  const handleChange = (changedFilter, value) => {
    const values = utils.toArray(value, true);

    onChange?.(utils.applyFilters(filter, values.reduce((o, v) => {
      o.value = o.value.concat(v);
      return o;
    }, {id: changedFilter.id, value: []}), [changedFilter.id]));
  };

  const handleClear = (filters) => {
    onChange?.(utils.applyFilters(filter, [], filters.map((filter) => filter.id)));
  };

  const handleSubmit = (e) => {
    onSubmit?.(e);
  }

  const handleSearch = (e) => {
    setInternalState(utils.updater({
      searchValue: e?.target?.value
    }, true));
  }

  const searchFieldRef = useRef(null);
  const internalSearchField = useMemo(() => ([{
    name: 'search',
    label: 'Search',
    inlineLabel: 'search',
    type: 'text',
    validation: 'text',
    initial: '',
    FormFieldProps: {
      ref: searchFieldRef,
      hiddenLabel: true,
      debounce: false,
      size: 'smaller'
    },
    prefix: <Icon icon={Search} />,
    postfix: tableFilters.state.searchValue?.length > 0 ? <ActionIconButton action={{
      icon: <Icon icon={Clear}/>,
      onClick: () => {
        searchFieldRef.current?.clear();
        dom.focusElement(searchFieldRef.current);
      }
    }} /> : null
  }]), [tableFilters.state.searchValue]);

  const renderGroupBadge = (tabGroup) => {
    if (tabGroup.activeCount > 0) {
      return <Badge badgeContent={<Tooltip title={'Clear'}>
                                    <Span onClick={(e) => {
                                            handleClear(tabGroup.filterGroups.reduce((a, fg) => a.concat(fg.filters), []));
                                            e.preventDefault();
                                            e.stopPropagation();
                                          }}
                                          onMouseDown={(e) => e.stopPropagation()}>
                                      {tabGroup.activeCount}
                                    </Span>
                                  </Tooltip>}
                    clickable={true}/>
    } else {
      return null;
    }
  }

  const renderFilterGroup = (filterGroup, showGroupTitle, idx) => {
    const renderedFilterGroup = <TableFiltersContent key={idx}
                                                     className="TableFilters-group"
                                                     showGroupTitle={showGroupTitle}
                                                     tableFilters={tableFilters}
                                                     filterGroup={filterGroup}
                                                     fieldData={fieldData}
                                                     onChange={handleChange}
                                                     onClear={handleClear}/>

    if (filterGroup.Group) {
      return <Component key={idx} 
                        Original={filterGroup.Group}
                        className="TableFilters-group"
                        tableFilters={tableFilters}
                        filterGroup={filterGroup}
                        fieldData={fieldData}
                        showGroupTitle={showGroupTitle}
                        onChange={handleChange}
                        onClear={handleClear}
                        renderedFilterGroup={renderedFilterGroup} />
    } else {
      return renderedFilterGroup;
    }
  }

  return <StyledTableFilters ref={ref} {...innerProps}>
    <Toolbar className="TableFilters-toolbar">
      <Box className="TableFilters-toolbar-title">
        <H6>Filters</H6>
      </Box>
      <ActionIconButton size="smaller"
                        density="sparse"
                        variant="outlined"
                        className="TableFilters-toolbar-close"
                        IconProps={{
                          size: 'smaller'
                        }}
                        action={closeAction} />
    </Toolbar>
    <Box className="TableFilters-content">
      <Box className="TableFilters-tabs">
        <Tabs orientation="vertical"
              value={tableFilters.state.activeTab}
              onChange={handleActiveTabChange}>
          {tabGroupsMemo?.map?.((tabGroup, idx) => {
            const tab = <Tab key={tabGroup.id}
                             value={tabGroup.id}
                             label={tabGroup.title}
                             disabled={tabGroup.general && (tabGroup.filterGroups?.length ?? 0) === 0}
                             badge={renderGroupBadge(tabGroup)} />;
            if (idx > 0 && tabGroupsMemo[idx - 1].general && !tabGroup.general) {
              return [
                <TabDivider key={`divider_${tabGroup.id}`} fullWidth/>,
                tab
              ];
            } else {
              return tab;
            }
          })}
        </Tabs>
      </Box>
      <Box className="TableFilters-panels">
        <Box className="TableFilters-panels-scroll">
          <InlineForm className="TableFilters-search"
                      onChangeDirect={handleSearch}
                      fields={internalSearchField} />

          {!hasFilters ? <Box className="TableFilters-empty">
            <P>No filters found</P>
          </Box> : null}

          {tabGroupsMemo?.map((tabGroup) => {
            return <TabPanel key={tabGroup.id}
                             className="TableFilters-panel"
                             value={tableFilters.state.activeTab}
                             index={tabGroup.id}>
              {tabGroup.filterGroups.map((filterGroup, idx) => {
                return renderFilterGroup(filterGroup, tabGroup.showTitles, idx);
              })}
            </TabPanel>
          })}
        </Box>

        <Box className="TableFilters-count">
          <Fab variant="extended"
               size="large"
               color="primary"
               onClick={handleSubmit}>
            {!isLoading ? `Show ${tableFilters.state.count ?? 0} results` : null}
            {isLoading ? <CircularProgress color="white"/> : null}
          </Fab>
        </Box>
      </Box>
    </Box>
  </StyledTableFilters>
});

TableFilters.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  count: PropTypes.number,
  filterGroups: PropTypes.array,
  filter: PropTypes.array,
  fieldData: PropTypes.object,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  isLoading: PropTypes.bool
};

TableFilters.defaultProps = {};

export default TableFilters;
