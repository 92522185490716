import React, {useEffect, useImperativeHandle, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useComponentProps, useEffectEvent} from 'helpers/hooks/utils';
import StyledCollectionSourceProfileCardContent
  from 'components/organisms/Cards/CollectionSourceProfileCardContent/CollectionSourceProfileCardContent.styles';
import utils from 'helpers/utils';
import constants from 'helpers/constants';
import Icon from 'components/atoms/Icons/Icon/Icon';
import Box from 'components/atoms/Layout/Box/Box';
import {useSnackbar} from 'components/organisms/Providers/SnackbarProvider/SnackbarProvider';
import SourceCard from 'components/organisms/Cards/SourceCard/SourceCard';
import InlineForm from 'components/organisms/Forms/InlineForm/InlineForm';
import Card from 'components/atoms/Cards/Card/Card';
import Button from 'components/atoms/Buttons/Button/Button';
import Add from '@mui/icons-material/Add';
import SourceSelectionDialog from 'components/organisms/Dialogs/SourceSelectionDialog/SourceSelectionDialog';
import AdvancedQuery from 'components/organisms/Queries/AdvancedQuery/AdvancedQuery';
import searchUtils from 'helpers/search';
import {useAuthClientId} from 'services/auth/auth.utils';
import {useDatabaseEntitySettings} from 'services/entity/entity.utils';

const CollectionSourceProfileCardContent = React.forwardRef((props, ref) => {
  const {
    card,
    content,
    source,
    collection,
    isDialog,
    fieldData,
    onValidating,
    onSubmit,
    onPatch,
    renderedLoader,
    ...innerProps
  } = useComponentProps(props, 'CollectionSourceProfileCardContent', {
    static: ['isAddNew', 'isEditing', 'isDeleted']
  });

  const formRef = useRef(null);
  const innerRef = useRef(null);

  const [override, setOverride] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [showSelectionDialog, setShowSelectionDialog] = useState(card.card.toggle);

  // ProfileCardContent handles form state
  useImperativeHandle(ref, () => formRef.current);

  const clientId = useAuthClientId();

  const isAddNew = !utils.isDefined(source?.sourceId);
  const isEditing = content.state.isEditing;
  const isOwned = +source?.ownerClientId === clientId || +source?.sourceId < 0;
  const isLoading = !utils.isDefined(source?.name) || !utils.isDefined(source?.type);

  const sourceDef = constants.data.lookup('sourceTypes', source?.type);

  const snackbar = useSnackbar();
  const [entityFieldData, entityFilterGroups] = useDatabaseEntitySettings();

  const fields = useMemo(() => {
    const readOnly = !isEditing;
    const readOnlyOwned = readOnly || !isOwned;

    const fields = [];
    let name = source?.name;
    if (source?.type === constants.sources.types.suggestions) {
      name = constants.data.lookup('sourceTypes', constants.sources.types.suggestions).label;
    }

    fields.push({
      name: 'name',
      label: 'Name',
      entity: 'source',
      placeholder: 'Type a name',
      type: constants.formFieldTypes.text,
      validation: constants.formFieldValidationTypes.text,
      initial: name ?? '',
      required: true,
      readOnly: readOnlyOwned,
      disabled: content.state.isSubmitting,
      FormFieldProps: {
        variant: 'inlineLabel',
        hiddenPlaceholder: readOnlyOwned,
        hiddenLabel: !isDialog ,
        debounce: Boolean(!isDialog) ? constants.debounce.input : false
      }
    });

    let comment = source?.comment;
    if (source?.type === constants.sources.types.suggestions) {
      comment = constants.data.lookup('sourceTypes', constants.sources.types.suggestions).description;
    }

    fields.push({
      name: 'comment',
      label: 'Description',
      inlineLabel: 'description',
      placeholder: 'Describe this source',
      type: constants.formFieldTypes.textarea,
      validation: constants.formFieldValidationTypes.text,
      conversion: constants.formFieldConversionTypes.value,
      entity: 'source',
      initial: comment ?? '',
      readOnly: readOnlyOwned,
      disabled: content.state.isSubmitting,
      FormFieldProps: {
        minRows: 2,
        variant: 'inlineLabel',
        hiddenPlaceholder: readOnlyOwned,
        hiddenLabel: !isDialog && Boolean(isEditing),
        debounce: Boolean(!isDialog) ? constants.debounce.input : false
      }
    });

    fields.push({
      name: 'filterType',
      label: 'Filter',
      type: constants.formFieldTypes.list,
      validation: constants.formFieldValidationTypes.text,
      conversion: constants.formFieldConversionTypes.value,
      options: constants.data.sourceFilterTypes,
      entity: 'source',
      initial: source?.filterType ?? constants.sources.filterTypes.extend,
      readOnly: readOnly,
      required: true,
      disabled: content.state.isSubmitting,
      FormFieldProps: {
        variant: 'inlineLabel',
        deselect: false,
        hiddenPlaceholder: readOnly,
        hiddenLabel: !isDialog && Boolean(isEditing),
        debounce: false,
        ListProps: {
          orientation: 'horizontal',
          gap: 16
        }
      }
    });

    fields.push({
      section: true,
      type: constants.formSectionTypes.info,
      info: (override ?? source?.filterType) ? 'Only the companies matching these filters below will be added to your suggestions queue' :
        'The companies matching these filters below and the general source filters will be added to your suggestions queue'
    })

    fields.push({
      name: 'filter',
      label: 'Filter',
      type: constants.formFieldTypes.component,
      validation: ![constants.sources.types.database].includes(source?.type) ? constants.formFieldValidationTypes.component :
        `${constants.formFieldValidationTypes.component}(${constants.formFieldValidationTypes.query})`,
      conversion: constants.formFieldConversionTypes.component,
      entity: 'source',
      valueProp: 'query',
      Component: <AdvancedQuery size="smaller"
                                fieldData={entityFieldData}
                                filterGroups={entityFilterGroups}/>,
      initial: {
        value: source?.filter ?? searchUtils.advancedQuery(),
        errors: false
      },
      readOnly: readOnly,
      required: [constants.sources.types.database].includes(source?.type),
      disabled: content.state.isSubmitting,
      FormFieldProps: {
        variant: 'staticLabel',
        hiddenLabel: true,
        autoFocus: false,
        hiddenPlaceholder: readOnly,
        debounce: Boolean(!isDialog) ? constants.debounce.input : false
      }
    });

    return fields
      .filter((f) => !f.readOnly || !utils.isEmpty(f.initial));
  }, [source, override, isOwned, isDialog, isEditing, entityFieldData, entityFilterGroups, content.state.isSubmitting]);

  const handleChange = (field, value) => {
    if (field.name === 'filterType') {
      setOverride(value === 'override');
    }
  }

  useEffect(() => {
    setOverride(source?.filterType === 'override');
  }, [source?.filterType]);

  const handleSubmit = (values) => {
    const fields = [{
      name: 'projectSources'
    }];

    const sourceIndex = collection?.projectSources.findIndex((s) => +s.sourceId === +source?.sourceId);
    const projectSource = collection?.projectSources[sourceIndex];

    const changes = {
      projectSources: [
        ...collection?.projectSources.slice(0, sourceIndex),
        {
          ...projectSource,
          ...(+source?.sourceId <= 0 ? source : null),
          ...(+source?.sourceId < 0 ? {
            name: values['name'],
            comment: values['comment']
          } : null),
          filterType: values['filterType'],
          filter: values['filter']?.parts?.length > 0 ? values['filter'] : null
        },
        ...collection?.projectSources.slice(sourceIndex + 1)
      ]
    };

    if (isOwned && +source?.sourceId > 0) {
      const name = `source-${source?.sourceId ?? 0}`;
      changes[name] = {...source, ...values};
      fields.push({
        name
      });

      return onSubmit?.(changes, null, fields, changes[name], true);
    } else {
      return onSubmit?.(changes, null, fields, changes.projectSources[sourceIndex], true);
    }
  };

  const handleValidating = (validating, dirty, errors) => {
    onValidating?.(validating, isAddNew || dirty, errors);
  }

  const onValidatingEvent = useEffectEvent(onValidating);
  useEffect(() => {
    if (isAddNew && isEditing) {
      onValidatingEvent?.(false, true, false);
    }
  }, [isAddNew, isEditing, onValidatingEvent]);

  const renderReadOnly = () => {
    const icon = (source?.subType ? sourceDef?.subTypes?.find((st) => st.value === source.subType)?.icon : null) ?? sourceDef?.icon;
    const img = (source?.subType ? sourceDef?.subTypes?.find((st) => st.value === source.subType)?.img : null) ?? sourceDef?.img;

    return <SourceCard variant="inset"
                       source={source}
                       icon={icon}
                       img={img}
                       isLoading={isLoading}
                       IconProps={{color: sourceDef?.color}}/>
  };

  const renderForm = () => {
    return <InlineForm ref={formRef}
                       className="CollectionSourceProfileCardContent-form"
                       fields={fields}
                       fieldData={fieldData}
                       onChange={handleChange}
                       onSubmit={handleSubmit}
                       onValidating={handleValidating}/>
  };

  const closeSelectionDialog = () => {
    setShowSelectionDialog(false);
    card.close();
  }

  const renderNew = () => {
    const handleSubmit = (type, data) => {
      setSubmitting(true);

      if (type === 'fileUpload') {
        const uploads = (collection?.uploads ?? []).concat([data]);
        const uploadsValue = utils.uniqueArray(uploads, null, true);

        utils.asPromiseCallback(onSubmit)({projectSources: uploadsValue}, null, [{name: 'fileUpload'}], data, true)
          .catch(() => {
            snackbar.show('Uploading file failed', null, {color: 'error', autoHideDuration: constants.delay.error});
          })
          .finally(() => {
            setSubmitting(false);
          });
      } else {
        const isOwned = +data?.ownerClientId === clientId || +data?.sourceId < 0;
        const sources = (collection?.projectSources ?? []).concat([isOwned ? data : {sourceId: data.sourceId, type: data.type}]);
        if (data?.type === constants.sources.types.database) {
          sources[sources.length - 1].sourceId = (collection?.projectSources ?? []).reduce((m, s) => +s.sourceId < m ? +s.sourceId : m, 0) - 1;
        }
        const sourcesValue = utils.uniqueArray(sources, 'sourceId', true);

        utils.asPromiseCallback(onSubmit)({projectSources: sourcesValue}, null, [{name: 'projectSources'}], data, true)
          .catch(() => {
            snackbar.show('Adding source failed', null, {color: 'error', autoHideDuration: constants.delay.error});
          })
          .finally(() => {
            setSubmitting(false);
          });
      }

      closeSelectionDialog();
    };

    const handleClose = (e) => {
      closeSelectionDialog();
      e.preventDefault();
    };

    const handleError = (err) => {
      closeSelectionDialog();
      snackbar.show(err, null, {color: 'error', autoHideDuration: constants.delay.error});
    };

    return <Card className="CollectionSourceProfileCardContent-new" fullWidth={true} fullHeight={true}>
      <Button variant="contained"
              startIcon={<Icon icon={Add} />}
              disabled={submitting}
              onClick={() => {
                setShowSelectionDialog(true);
              }}>
        Add source
      </Button>
      {showSelectionDialog ? <SourceSelectionDialog open={true}
                                                    collection={collection}
                                                    onClose={handleClose}
                                                    onError={handleError}
                                                    onSubmit={handleSubmit}/> : null}
    </Card>
  };

  delete innerProps.source;
  innerProps.className = utils.flattenClassName(innerProps.className, {
    isAddNew: isAddNew,
    isEditing: isEditing
  });

  return <StyledCollectionSourceProfileCardContent ref={innerRef} {...innerProps}>
    <Box className="CollectionSourceProfileCardContent-content">
      {!isAddNew ? (!isEditing ? renderReadOnly() : renderForm()) : renderNew()}
    </Box>
  </StyledCollectionSourceProfileCardContent>
});

CollectionSourceProfileCardContent.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  card: PropTypes.object,
  content: PropTypes.object,
  collection: PropTypes.object,
  isDialog: PropTypes.bool,
  fieldData: PropTypes.object,
  onValidating: PropTypes.func,
  onSubmit: PropTypes.func,
  onPatch: PropTypes.func,
};

CollectionSourceProfileCardContent.defaultProps = {
};

export default CollectionSourceProfileCardContent;


